<template>
  <div class="home">
    <main>
      <div class="main" v-for="(item,index) in configurationList" :key="index">
        <div class="left">
          <img :src="$utils.imgTrow(item.poster)">
        </div>
        <div class="right">
          <div class="right-title">{{ item.main_heading }}</div>
          <div class="right-desc">{{ item.sub_heading }}</div>
          <div class="right-content" v-html="item.synopsis"></div>
          <div class="cursorP" @click="hdBtn(item.link)">{{ item.link_text }}</div>
        </div>
      </div>
      <!-- <div class="main">
        <div class="left">
          <img :src="$t('messages.Chinese.main.imgUrl.shangzhai') ">
        </div>
        <div class="right">
          <div class="right-title">{{ $t('messages.Chinese.main.title.sz')  }}</div>
          <div class="right-desc">{{ $t('messages.Chinese.main.content.sz') }}</div>
          <div>{{ $t('messages.Chinese.main.contentOne.sz') }}</div>
          <div>{{ $t('messages.Chinese.main.contentTwo.sz') }}</div>
          <div>{{ $t('messages.Chinese.main.contentThree.sz') }}</div>
          <div class="titleMargin cursorP"  @click="szBtn()">{{ $t('messages.Chinese.main.contentFour.sz') }}</div>
        </div>
      </div> -->
    </main>
    <div class="optionPopup" v-if="isOptionPopup">
      <div class="optionPopupText">
        {{ $t('messages.Chinese.optionPopupText') }}
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isOptionPopup: false,
      configurationList: [],
    }
  },
  created() {
    // console.log(process)
    this.getCon()
  },
  computed: {
    ...mapState({
      lang: (state) => state.init.lang,
    }),
  },
  watch:{
    lang(nval){
      this.getCon()
    }
  },
  methods: {
    hdBtn(url) {
      if(url){
        window.open(url)

      }else{
        this.szBtn()
      }
    },
    szBtn() {
      // window.open("https://www.sunzstudio.com/#/")
      this.isOptionPopup = true
      setTimeout(() => {
        this.isOptionPopup = false
      }, 1000);
    },
    getCon(){
      this.$api.configurationAll().then(res => {
      if (res.code == 0) {
        res.data.map(i=>{
          i.synopsis = this.$utils.textRepl(i.synopsis)
        })
        this.configurationList = res.data
      }
    })
    }
  },

}
</script>
<style lang="less">
.optionPopup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .optionPopupText {
    font-size: 20px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 10px 20px;
  }
}
.titleMargin {
  margin: 10px 0;
}
.cursorP {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50px;
}
.right-title {
  font-size: 50px;
  font-weight: 600;
  color: #a10000;
}
.right-desc {
  font-size: 26px;
  margin: 10px 0 20px;
}
.right-content{
 line-height: 30px;
}
main {
  width: 100%;
  height: 100%;
  margin: 30px 0;

  .main {
    margin: 0 auto 10px;
    width: 1300px;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      width: 58%;
      height: 430px;
      img {
        width: 100%;
        height: 430px;
        cursor: pointer;
      }
    }

    .right {
      width: 42%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      position: relative;
      h1 {
        color: #a10000;
      }
    }
  }
}
</style>
