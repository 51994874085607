<template>
  <div id="app">
    <div class="navs">
      <div class="nav-logo">
        <!-- logo -->
        <img class="iconLogo" v-if="logoImg" :src="$utils.imgTrow(logoImg)" alt="">
      </div>
      <div class="nav-content">
        <div class="h-left">
          <router-link to="/" exact>{{ $t('messages.Chinese.nav.home') }}</router-link>
          <router-link to="/Payment" exact>{{ $t('messages.Chinese.nav.Payment') }}</router-link>
          <router-link to="/user">{{ $t('messages.Chinese.nav.userAgreement') }}</router-link>
          <router-link to="/privacy">{{ $t('messages.Chinese.nav.privacyAgreement') }}</router-link>
          <router-link to="/ContactView" >{{ $t('messages.Chinese.nav.about') }}</router-link>
          <router-link to="/PaymentDetalis" v-if="pShow">{{ $t('messages.Chinese.nav.Purchase') }}</router-link>
        </div>
        <div class="h-right">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ $t('messages.Chinese.nav.language')}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="clickLang('en')">English</el-dropdown-item>
              <!-- <el-dropdown-item @click.native="clickLang('cn')">简体中文</el-dropdown-item> -->
              <el-dropdown-item @click.native="clickLang('cn')">繁体中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <div class="loginBtn">登录</div> -->
      </div>
    </div>
    <div class="contentss">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <loginModel />
    <FooterView />
    
  </div>
</template>
<script>
import FooterView from "@/components/FooterView/index.vue"
import { mapState } from "vuex";

export default {
  components: {
    FooterView
  },
  data() {
    return {
      logoImg: '',
      pShow: false,
      tabIndex: 0,
      tabList: [{
        label: '验证码登录',
        value: 0
      }, {
        label: '密码登录',
        value: 1
      }]
    }
  },
  created() {
    this.$api.logoSetDataAll().then(res => {
      if (res.code == 0) {
        this.logoImg = res.data.logo ? res.data.logo : ''
      }
    })
    this.$bus.$on('routerLinke', (type) => {
      this.pShow = type
    })
    this.$store.dispatch('init/getInits')
    

  },
  computed: {
    ...mapState({
      lang: state => state.init.lang
    })
  },
  watch: {
    lang(nVal) {
      this.$store.dispatch('init/getInits')
    }
  },
  methods: {

    clickLang(e) {
      this.$i18n.locale = e

      this.$store.commit('init/setLanguage', e)
      this.$store.dispatch('listrecombination')
      this.$store.dispatch('productPrimitivehandle')
      this.$store.dispatch('dataHandle')
    },
  }
}
</script>
<style lang="less" scoped>


.contentss {
  padding: 80px 0px 0;
  min-height: calc(100vh - 150px);
}
.iconLogo {
  width: 240px;
  height: 30px;
}
body {
  margin: 0;
  padding: 0;
  user-select: none;
}
p {
  margin: 0 !important;
}
#app {
  .navs {
    background-color: #363636;
    padding: 0 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    color: #fff;
    height: 80px;

    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    right: 0;
    z-index: 9;
    .nav-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .h-left {
      display: flex;
      justify-content: space-between;

      a {
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        padding: 0 30px;
      }

      .el-button {
        border: 0;
        font-size: 18px;
        color: #fff;
      }

      .router-link-active {
        color: #ffed7d;
        // font-weight: bold;
      }
    }
  }

  .h-right {
    margin-left: 30px;
    display: flex;
    align-items: center;

    .el-dropdown-link {
      cursor: pointer;
      color: #fff;
      font-size: 16px;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
</style>
