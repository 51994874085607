
export default ({
  service,
  request,
  serviceForMock,
  requestForMock,
  mock,
  tools
}) => ({
  // 登录校验
  checkLogin(data = {}) {
    return request({
      url: '/api/checkLogin',
      method: 'post',
      params: data
    })
  },
})
