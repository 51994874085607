<template>
  <div class="x-c loginModel" :class="{ 'bundleDelShow': bundleDelShowAn }" v-if="bundleDelShow">
    <div class="loginModel-content">
      <div style="padding: 24px;">
        <img class="closeImg" src="../../assets/imgs/closeImg.png" alt="" @click="hiad()">

        <div class="loginTop">
          <div class="x-c">
            <img class="logoImg" :src="gameData.icon ? $utils.imgTrow(gameData.icon) : '' " alt="">
          </div>
          <div class="x-bc tab">
            <div class="tab-item text-pointer" v-for="(item, index) in tabList" :key="index"
              @click="tabChange(item, index)">
              {{ item.label }}
              <div class="selectBt" v-if="tabIndex == index"></div>
            </div>
            <!-- <div class="selectBt" :style="{ 'left': tabIndex == 0 ? '0px' : '166px' }"></div> -->
          </div>
          <div class="y-start" v-if="loginType == 'email'">
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="text" :placeholder="$t('login.Eight')">
              </div>
              <div class="tipsText">{{ $t('login.Eight') }}</div>
            </div>
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="text" :placeholder="$t('login.Four')">
                <div class="immobilization text-pointer">{{ $t('login.Nine') }}</div>
              </div>
              <div class="tipsText" v-if="isSubmit && !formDataAcount.account">{{ $t('l.six') }}</div>
            </div>
          </div>

          <div class="y-start" v-if="loginType == 'account'">
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="text" v-model="formDataAcount.account"
                  :placeholder="$t('login.Tow')">
              </div>
              <div class="tipsText" v-if="isSubmit && !formDataAcount.account">{{ $t('login.Tow') }}</div>
            </div>
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="password" v-model="formDataAcount.password"
                  :placeholder="$t('login.Therr')">
              </div>
              <div class="tipsText" v-if="isSubmit && !formDataAcount.password">{{ $t('login.Therr') }}</div>
            </div>
          </div>

          <div class="y-start" v-if="loginType == 'signIn'">
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="text" v-model="formDatasignIn.account"
                  :placeholder="$t('login.Tow')">
              </div>
              <div class="tipsText" v-if="isSubmit && !formDatasignIn.account">{{ $t('login.Tow') }}</div>
            </div>
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="password" v-model="formDatasignIn.password"
                  :placeholder="$t('login.Therr')">
              </div>
              <div class="tipsText" v-if="isSubmit && !formDatasignIn.password">{{ $t('login.Therr') }}</div>
            </div>
            <div class="inputItem">
              <div class="inputItemB">
                <input class="inputItemItem" type="password" v-model="formDatasignIn.password_confirm"
                  :placeholder="$t('login.Therr')">
              </div>
              <div class="tipsText" v-if="isSubmit && !formDatasignIn.password_confirm">{{ $t('login.Therr') }}</div>
            </div>
          </div>
          <div class="signInText" @click="goSignin">注册</div>
        </div>


        <div class="x-f ysanyh text-pointer" @click="ischeckedIcon = !ischeckedIcon">
          <img class="checkedIcon" src="../../assets/imgs/checkedIconNo.png" alt="" v-if="!ischeckedIcon">
          <img class="checkedIcon" src="../../assets/imgs/checkedIconYes.png" alt="" v-else>
          <div class="x-start flex-wrap ">
            {{ $t('l.seven') }}
            <p class="agreement" @click.stop="openRouter(1)">《{{ $t('login.TermsService') }}》</p>
            <p class="agreement" @click.stop="openRouter(1)">《{{ $t('login.PrivacyAgreement') }}》</p>
          </div>
        </div>
        <div class="loginBtn text-pointer" @click="submit">
          {{ loginType !== 'signIn' ? $t('login.Five') : $t('login.Ten') }}
        </div>
      </div>
      <div class="flex warning">
        <img class="s_login_warning" src="../../assets/imgs/s_login_warning.png" alt="">
        <div>
          <div>本网站仅支持官方游戏邮箱账号进行登录。</div>
          <div class="color-999">第三方邮箱账号（如谷歌登录、苹果登录等）请先前往游戏内进行莉莉丝邮箱绑定。</div>
        </div>
      </div>
    </div>
    <optionPopupText ref="optionPopupText" />
    <loadingMark ref="loadingMark" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { sdk } from '../../mixins'
import utils from '../../utils/utils';

export default {
  mixins:[sdk],
  data() {
    return {
      bundleDelShow: false,
      bundleDelShowAn: false,
      tabIndex: 0,
      tabList: [],
      ischeckedIcon: false,
      // zj   123456
      formDataAcount: {
        account: '',
        password: '',
      },
      formDataEmaeli: {
        email: '',
        code: ''
      },
      formDatasignIn: {
        account: '',
        password: '',
        password_confirm: '',
      },
      formDataMark: {},
      loginType: 'account',  // tourist 游客登录 account 账号登录  email 邮箱登录 signIn 账号注册  
      tripartiteImmobilization: ['google', 'faceBook', 'apple', 'tourist'],
      tripartiteList: [
        // {
        //   label: 'google',
        //   img: require('../../assets/imgs/gicon.png'),
        // },
        // {
        //   label: 'faceBook',
        //   img: require('../../assets/imgs/ficon.png'),
        // },
        // {
        //   label: 'apple',
        //   img: require('../../assets/imgs/apple.png'),
        // },
        // {
        //   label: 'account',
        //   img: require('../../assets/imgs/account.png'),
        // },
        // {
        //   label: 'tourist',
        //   img: require('../../assets/imgs/tourist.png'),
        // },
        // {
        //   label: 'email',
        //   img: require('../../assets/imgs/emali.png'),
        // },
        // {
        //   label: 'phone',
        //   img: require('../../assets/imgs/phone.png'),
        // },

      ],
      isSubmit: false,
      isLoading: false,
      time: 0,
      popupPrompt: '', // 提示文字,
      timestamp: '', // 暂存时间戳
      // faceBook 登录信息

    }
  },

  computed: {
    ...mapState({
      lang: state => state.init.lang,
      gameData: state => state.payment.gameData,
      serverData: (state) => state.payment.serverData,

    })
  },

  created() {
    this.$bus.$on('logMd', (data) => {
      this.openPop()
    })
    this.getInit()
  },

  mounted() {
    this.getGameData()

  },
  watch: {
    lang(nVal) {
      this.getInit()
    },
    gameData() {
      this.getGameData()
    },
    isLoading(nval) {
      nval ? this.$refs.loadingMark.open() : this.$refs.loadingMark.hide()
    }
  },
  methods: {
    getInit() {
      this.tabList = [{
        label: this.$t('l.tow'),
        type: 'account',
        value: 1,
        isOpen: true,
      }, {
        label: this.$t('l.one'),
        value: 0,
        isOpen: false
      }]
    },
    
    
    openPop(item) {
      this.bundleDelShow = true
      setTimeout(() => {
        this.bundleDelShowAn = true
      }, 100);
    },
    ischeckedCli() {
      this.ischeckedIcon = !this.ischeckedIcon
    },
    goSignin() {
      this.loginType = 'signIn';
      this.tabIndex = null
      this.isSubmit = false
    },
    hiad() {
      this.bundleDelShowAn = false
      setTimeout(() => {
        this.bundleDelShow = false
      }, 300);

      for (let k in this.formDataAcount) {
        this.formDataAcount[k] = ''
      }
      for (let k in this.formDatasignIn) {
        this.formDatasignIn[k] = ''
      }
      this.isSubmit = false
      this.loginType = 'account'
      this.tabIndex = 0
    },
    tabChange(item, index) {
      if (!this.tabList[index].isOpen) {
        this.$refs.optionPopupText.open()
      } else {
        this.tabIndex = index
        this.loginType = item.type
      }
    },
    // 登录前判断 
    loginTy(item) {
      let type = item.label || item
      if (this.tripartiteImmobilization.findIndex(i => i == type) == -1) {
        this.loginType = type
      }
      if (type == 'tourist') {
        this.loginTyLogin(this.SDKURL,'visitor').then(res => {
          if (res.code == 200) {
            this.unifiedLoginCheck(res.data.token)
          }
        })
      }
      this.isSubmit = false
      this.formData = { ...this.formDataMark }
    },
    sendCode() {
      let that = this
      if (that.time != 0) {
        return
      }
      this.TextShow(this.$t('login.sentSus'))
      that.time = 60;
      // 开启定时器
      var timer = setInterval(function () {
        // 判断剩余秒数
        if (that.time == 0) {
          // 清除定时器和复原按钮
          clearInterval(timer);
        } else {
          that.time--;
        }
      }, 1000);
    },


    async submit() {
      if (!this.ischeckedIcon) {
        this.$refs.optionPopupText.open(this.$t('login.pleaseCheck'))
        return
      }
      this.isSubmit = true
      if (this.loginType == 'account') {
        if (this.formDataAcount.account && this.formDataAcount.password) {
          await this.loginTyLogin(this.SDKURL,'account', this.formDataAcount).then(res => {
            if (res.code == 200) {
              let data = {
                token: res.data.token,
                id: this.gameData.id,
                server_id: this.serverData.id,
                sign_timestamp: this.timestamp
              }
              this.$api.checkLogin(data).then(resd => {
                if (resd.code == 0) {
                  this.$store.commit('setUserInfo', { 
                    ...resd.data, 
                    // delete_time:resd.data.delete_status == 1 ? parseInt(new Date() / 1000) + resd.data.delete_time : '',
                    sdk_user_id:res.data.user_id,
                    appid: this.gameData.appid 
                  })
                  this.$refs.optionPopupText.open(this.$t('login.loginSus'))
                  setTimeout(() => {
                    this.hiad()
                  }, 1000);
                } else {
                  this.$refs.optionPopupText.open(resd.msg)
                }
              }).finally(() => {
                this.isLoading = false
              })
            }
          }).finally(() => {
            this.isLoading = false
          })
        }
      }
      if (this.loginType == 'signIn') {
        if (this.formDatasignIn.account && this.formDatasignIn.password && this.formDatasignIn.password_confirm) {
          await this.loginTyLogin(this.SDKURL,'signIn', this.formDatasignIn).then(res => {
            if (res.code == 200) {
              this.$refs.optionPopupText.open(this.$t('login.registerSus'))
              this.loginType = 'account'
              this.tabIndex = 0
              this.formDataAcount = { ...this.formDatasignIn }
              for (let k in this.formDatasignIn) {
                this.formDatasignIn[k] = ''
              }
            }
          })
        }
      }
    },
    // SDK登录
    unifiedLoginCheck(token) {
      this.isLoading = true
      let data = {
        code: this.formDataAcount.account,
        pass: this.formDataAcount.password,
        type: this.loginType
      }
      this.$api.interiorLogin(data).then(res => {
        if (res.code == 0) {
          this.isLoading = false
          this.TextShow(this.$t('login.loginSus'))
          this.$store.commit('setUserInfo', res.data)
          setTimeout(() => {
            this.$router.back()
          }, 1000);
        }
        // else {
        //   this.TextShow(res.message)
        // }
      }).finally(() => {
        this.isLoading = false
      })

    },
    // 谷歌登录
    OnGoogleAuthSuccess(idToken) {
      console.log(idToken, "tokesdasdasd") //返回第三方结果信息 默认是全token 要用jsonwebtoken 解析
      // Receive the idToken and make your magic with the backend
    },
    // 谷歌授权失败
    OnGoogleAuthFail(error) {
      console.log(error)
    },

    //faceBook
    onSignInSuccess(response) {
      // 登录成功回调
      // FB.api('/me', dude => {
      //   console.log(`Good to see you, ${dude.name}.`)
      // })
      console.log(response) //返回第三方的登录信息 tolen等
    },
    onSignInError(error) {
      //登录失败回调
    },

    // 苹果登录
    onSuccess(data) {
      console.log(data);
      this.signedIn = true;
    },
    onSuccessLogout() {
      this.signedIn = false;
    },
    onFailure(error) {
      console.log(error);
    },



     
  },
}
</script>
<style lang="scss" scoped>
.signInText {
  position: absolute;
  right: 20px;
  bottom: -30px;
  color: rgb(69, 69, 228);
  cursor: pointer
}

.warning {
  padding: 15px 10px;
  background: #fff7e9;
  border-radius: 0 0 10px 10px;
  border: 1px solid #ffdda9;
  line-height: 20px;

  .s_login_warning {
    height: 30px;
    width: 30px;
    margin-inline-end: 10px;
  }
}

.loginBtn {
  padding: 14px;
  border-radius: 25px;
  box-shadow: 0 6px 7px 0 rgba(211, 0, 0, .25);
  background: #d30000;
  text-align: center;
  color: #ffffff;
}

.ysanyh {
  padding: 15px 0;

  .checkedIcon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  .agreement {
    color: red;
    text-wrap: nowrap;
    cursor: pointer;

    &:hover {
      text-decoration-line: underline
    }
  }
}

.loginModel {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .closeImg {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  .loginModel-content {
    position: relative;
    background: #ffffff;
    border-radius: 14px;
    transform: scale(0);
    opacity: 0;
    font-size: 12px;
    width: 380px;
    transition: all .3s ease-in-out;

    .loginTop {
      position: relative;
      margin-bottom: 40px;

      .logoImg {
        width: 80px;
        height: 80px;
        margin: 10px 0 24px;
      }

      .tab {

        .tab-item {
          width: 166px;
          padding: 14px 0;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          position: relative;
        }

        .selectBt {
          width: 166px;
          height: 2px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          transition: width 0.3s, left 0.3s, right 0.3s;

          &::after {
            content: "";
            position: absolute;
            width: 36px;
            height: 2px;
            left: 50%;
            transform: translate(-50%);
            background: #d30000;
            border-radius: 1px;
          }
        }
      }

      .inputItem {
        width: 100%;

        .inputItemItem {
          width: 100%;
          background: none;
          outline: none;
          border: 0px;

        }

        .tipsText {
          color: red;
          margin-top: 8px;
          margin-left: 8px;
        }

        .inputItemB {
          position: relative;
          border-bottom: 1px solid #f0f0f0;
          padding: 14px 8px;
        }
      }

      .immobilization {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #d30000;
      }
    }
  }
}

.bundleDelShow {
  .loginModel-content {
    transform: scale(1);
    opacity: 1;
  }
}
</style>