import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
import bus from '@/utils/bus'


Vue.use(Router)

const constantRoutes = [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      keepAlive: true,
    },
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      keepAlive: true,
    },
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      keepAlive: true,
    },
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/Payment',
    name: 'Payment',
    meta: {
      title: '商品页',
      keepAlive: true,
    },
    component: () => import('@/views/Payment/Payment')
  },
  {
    path: '/ContactView',
    name: 'ContactView',
    meta: {
      title: '联系我们',
      keepAlive: true,
    },
    component: () => import('@/views/ContactView')
  },
  
  {
    path: '/PaymentDetalis',
    name: 'PaymentDetalis',
    meta: {
      title: '支付详情',
      keepAlive: true,
    },
    component: () => import('@/views/Payment/PaymentDetalis')
  }, {
    path: '/PaymentDetalisM',
    name: 'PaymentDetalisM',
    meta: {
      title: '支付详情',
      keepAlive: true,
    },
    component: () => import('@/views/Payment/PaymentDetalisM')
  },
  {
    path: '/PaymentCallback',
    name: 'PaymentCallback',
    meta: {
      title: '充值状态',
      keepAlive: false,
    },
    component: () => import('@/views/Payment/PaymentCallback')
  },
  {
    path: '/PaymentCallbackM',
    name: 'PaymentCallbackM',
    meta: {
      title: '充值状态',
      keepAlive: false,
    },
    component: () => import('@/views/Payment/PaymentCallbackM')
  },
  {
    path: '/orderList',
    name: 'orderList',
    meta: {
      title: '订单列表',
      keepAlive: false,
    },
    component: () => import('@/views/orderList/orderList')
  },
  {
    path: '/orderListM',
    name: 'orderListM',
    meta: {
      title: '订单列表',
      keepAlive: false,
    },
    component: () => import('@/views/orderList/orderListM')
  },
]

const router = new Router({
  mode: 'hash',
  routes: constantRoutes,
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/PaymentDetalis' && to.path !== '/orderList' && to.path !== '/PaymentCallback'  && store.state.user.userInfo.loginToken) {
    next({path:'/PaymentDetalis'})

  }else {
    next()
  }
});

router.afterEach((to, from) => {
  bus.$emit("routerLinke", to.path !== '/PaymentDetalis' && to.path !== '/orderList' && to.path !== '/PaymentCallback' ? false : true);
  return true
})

export default router
