const messages = {
  Chinese: {
    optionPopupText: "暂未开放",
    bundle: "礼包选择",
    instructions: "充值说明",
    products: "没有产品",
    banner: require("../../assets/imgs/banner-cn.png"),
    nav: {
      home: "首頁",
      Payment: "支付",
      userAgreement: "用戶協議",
      privacyAgreement: "隱私協議",
      about: "聯系我們",
      Purchase: "游戏充值",
      language: "繁体中文",
    },
    main: {
      imgUrl: {
        shangzhai: require("../../assets/imgs/shangzhai.png"),
        haidao: require("../../assets/imgs/haidao.png"),
      },
      title: {
        hd: "海盗奇兵",
        sz: "禦劍封魔",
      },
      content: {
        hd: "海洋孕育萬物，星空凝視衆生",
        sz: "東勝神州，魔界入侵。壹時間天下大亂，魔族所到之處哀鴻遍野，民不聊生",
      },
      contentOne: {
        hd: "世界翻天覆地，唯星辰大海壹如既往。大陸的沈沒，海面的上升，塵封的故事回蕩在大海的波濤之中",
        sz: "各大宗門召集弟子前往神州平定動亂",
      },
      contentTwo: {
        hd: "而妳，作爲被選中的船長，獲得了解開這個世界的秘密的機會",
        sz: "而你將操控潛力無限的修仙",
      },
      contentThree: {
        hd: "破敗的遺迹，性格各異的夥伴，故事的帷幕等著妳去揭開",
        sz: "",
      },
      contentFour: {
        hd: "那麽，妳准備好「揚帆起航」了嗎",
        sz: "",
      },
    },
    // 简介
    blurb: {
      title: {
        hd: "激情無限 海盜聯盟",
        sz: "全球玩家 巅峰競技",
      },
      content: {
        hd1: "挑戰強力的聯盟boss！",
        hd2: "搶奪海域的所有權，稱霸海域！",
        hd3: "與其他船長組建最強海賊聯盟，打造傳奇盛宴！",
        sz1: "加入競技場，與來自世界各地的廣大玩家PK！",
        sz2: "向排行榜和最高榮耀發起猛攻，贏取強大的戰利品！",
      },
      imgUrl: {
        hd1: require("../../assets/imgs/hd1.jpg"),
        hd2: require("../../assets/imgs/hd2.jpg"),
        yj1: require("../../assets/imgs/yj1.jpg"),
        yj2: require("../../assets/imgs/yj2.jpg"),
      },
    },
    // 用户协议
    user: {
      title: "科樂賽思服務協議",
      content: "1.簡介",
      contentOne: `
               <p>1.1.歡迎使用科樂賽思，如果您使用科樂賽思應用程序版本，而不是在特定國家/地區（包括中國大陸、日本和韓國）分發的版本，則在這些使用條款和條件中使用（“條款”），“科樂賽思”、“我們”、“我方”或“我們的”是指Colocess Technology Co。，有限公司及其相關附屬公司在相應國家和/或地區分銷科樂賽思應用程序。</p>
               <p>1.2.我們爲我們的用戶（“科樂賽思用戶”）提供：</p>
               <ul>
                 <li><p>1.2.1移動、桌面平台或設備的應用程序，以及可在第三方社交網絡服務（“SNS”）上訪問的web應用程序（統稱爲“科樂賽思應用程序”）</p></li>
                 <li><p>1.2.2與科樂賽思應用程序和第三方應用程序（定義如下）相關的遊戲發行商網絡、衆包服務和平台（“網絡”）</p></li>
                 <li><p>1.2.3域名和子域，包括Colocess.com（統稱爲“網站”），以及科樂賽思應用程序和我們的網站中的網絡論壇或消息板（統稱爲我們的“論壇”）</p></li>
                 <li><p>1.2.4我們的任何和所有科樂賽思應用程序、網絡、網站和論壇在本文中稱爲我們的“服務”。</p></li>
               </ul>
               <p>1.3請仔細閱讀這些條款，因爲它們管轄您訪問和使用我們的服務、訪問內容和用戶內容（定義如下），並且具有法律約束力。</p>
               <p>1.4您確認並同意，通過點擊“注冊”按鈕（如適用），或訪問或使用我們的服務，或在我們的服務上或通過我們的服務下載或發布任何內容，表明您已閱讀、理解並同意受這些條款的約束，無論您是否已注冊爲會員（定義如下）。如果您不同意這些條款，那麽您無權訪問或使用我們的服務或訪問內容。某些服務以及您對某些科樂賽思內容的訪問或使用可能會發布不同的、特定于服務的或特定于科樂賽思屬性的條款和條件（“規則”），或者可能要求您同意並接受此類附加規則。如果這些條款與特定于服務的規則或特定于科樂賽思內容的規則之間存在沖突，則在您使用或訪問此類服務或C科樂賽思屬性時，適用的規則將優先。此外，科樂賽思提供對第三方移動或桌面應用程序的訪問,通過我們的服務提供的應用程序（各稱爲“第三方應用程序”），由第三方發行商（各稱“第三方發行商”）設計。您理解並同意，對任何第三方應用程序的訪問和使用應遵守該第三方申請程序的第三方出版商提供的使用條款和條件（“第三方條款”）。</p>
               <h3>2.修改</h3>
               <p>科樂賽思有權自行決定在全球或個人基礎上修改、終止或終止我們的服務，包括其中的任何部分，或在任何時候修改這些條款，而無需事先通知。如果我們修改這些條款，我們將發布修改後的條款，或以其他方式向您提供修改通知。在我們發布了對這些條款的修改或向您提供了修改通知後，繼續訪問或使用我們的服務，即表示您同意受修改後的條款的約束。如果您不接受修改後的條款，您唯壹的追索權就是停止使用我們的服務。</p>
               <h3>3.不得非法或禁止使用</h3>
               <p>通過使用我們的服務，您向科樂賽思聲明並保證，您不會將我們的服務或從我們的服務中獲得的任何內容用于任何非法或本條款禁止的目的。</p>
               <h3>4.資格和注冊</h3>
               <p>4.1爲了訪問我們服務的完整功能集，並在我們的服務上或通過我們的服務發布任何用戶內容，您必須注冊創建壹個科樂賽思帳戶（“科樂賽思帳戶”）（任何科樂賽思賬戶，統稱爲“科樂賽思帳戶”）並成爲“會員”</p>
               <p>4.2您同意，如果您未滿18歲或您獲得服務的法定成年年齡，您表示您的法定監護人已閱讀、理解並同意這些條款。</p>
               <p>4.3在注冊科樂賽思帳戶時，您同意監控該帳戶以限制未成年人的任何使用。您對未成年人未經授權使用我們的服務承擔全部責任，包括未成年人使用您的信用卡或其他支付或結算工具或設備。在注冊過程中，您將被要求提供某些信息，包括您的電子郵件地址，並將建立用戶名和密碼。您同意在注冊過程中提供准確、最新和完整的信息，並更新這些信息以保持其准確性、最新性和完整性。如果在注冊過程中或之後提供的任何信息被證明不准確、不及時或不完整，科樂賽思保留暫停或終止您的科樂賽思賬戶的權利。</p>
               <p>4.4如果您不是會員，您只能以有限的方式浏覽或使用我們的服務中不限于會員的部分。您有責任保護您的密碼。您同意不向任何第三方披露您的密碼，也不允許任何第三人使用您的科樂賽思帳戶，並且您同意對您科樂賽思賬戶下的任何活動或行動承擔全部責任，無論您是否授權此類活動或行動。您將立即通知科樂賽思任何未經授權使用您的科樂賽思帳戶的情況。</p>
               <p>4.5盡管我們使用“您的”壹詞來描述您注冊的共管賬戶，但您同意您對共管賬戶沒有任何所有權或其他財産權益，並且您進壹步同意，共管賬戶中的所有權利現在和將來都歸共管所有，並以共管的利益爲受益人。</p>
               <h3>5.隱私</h3>
               <p>科樂賽思的隱私政策也適用于我們的服務。如果您對科樂賽思隱私政策有任何疑問，請通過電子郵件與我們聯系：kf@colocess.com</p>
               <h3>6.內容</h3>
               <p>6.1某些類型的內容可在我們的服務上或通過我們的服務獲得：</p>
               <ul>
                 <li><p>6.1.1用戶內容是指會員通過我們的服務上傳、傳輸或提交的文本、數據、圖形、圖像、照片、視頻或視聽內容、超文本鏈接以及任何其他內容。</p></li>
                 <li><p>6.1.2 科樂賽思內容是指文本、數據、圖形、圖像、插圖、表格、文檔、營銷材料、外觀屬性、Colocess或我們的許可方的商標和徽標、科樂賽思應用程序和第三方應用程序，以及科樂賽思在我們的服務上或通過我們的服務提供的其他內容，包括任何自定義，但不包括用戶內容。</p></li>
                 <li><p>6.1.3自定義是指由于成員或非注冊用戶使用科樂賽思的交互式模板或設計工具自定義科樂賽思角色而對科樂賽思內容進行的修改、設計或增強。爲避免疑義，“自定義設置”不包括“用戶內容”。</p></li>
               </ul>
               <h3>7.所有權</h3>
               <p>7.1我們的服務和訪問內容受中華人民共和國和外國的版權、商標和其他法律保護。</p>
               <p>7.2除本條款明確規定外，科樂賽思和我們的許可方獨家擁有服務和科樂賽思內容的所有權利、所有權和權益，包括所有相關的知識産權，包括任何專利、版權、商標、服務標記、商品名、數據庫權、域名權、上述任何內容的應用程序，道德權利和商業秘密權利（“知識産權”）。</p>
               <p>7.3 科樂賽思將獨家擁有任何定制的所有權利、所有權和權益，您特此放棄根據版權法或其他規定在任何定制中享有的任何和所有權利。您不會刪除、更改或模糊我們的服務或科樂賽思內容中包含或隨附的任何版權、商標、服務標記或其他所有權聲明。</p>
               <h3>8 科樂賽思內容許可證</h3>
               <p>在您遵守這些條款的前提下，科樂賽思特此授予您壹個有限的、非排他性的、不可轉讓的、不分許可的許可證，允許您僅出于個人和非商業目的訪問、查看、下載和打印（如適用）任何科樂賽思內容。除非本條款明確允許，否則您不得使用、複制、改編、修改、准備基于、分發、許可、出售、轉讓、公開展示、公開執行、傳輸、流式傳輸、廣播或以其他方式利用服務或科樂賽思內容的衍生作品。科樂賽思或其許可方擁有或控制的任何知識産權下，未通過暗示或其他方式向您授予任何許可證或權利，但本條款明確授予的許可證和權利除外。</p>
               <h3>9.用戶內容</h3>
               <p>9.1通過在我們的服務上或通過我們的服務提供任何用戶內容，您特此向科樂賽思授予壹項全球範圍內的、不可撤銷的、永久的、非排他性的、可轉讓的、免版稅的許可證，並有權僅在我們的服務上、通過或通過我們服務分許可、使用、複制、改編、修改、分發、許可、出售、轉讓、公開展示、公開執行、傳輸、流式傳輸、廣播和以其他方式利用此類用戶內容。科樂賽思不對任何此類用戶內容主張任何所有權，本條款中的任何內容都不會被視爲限制您使用和利用任何此類用戶屬性的任何權利。</p>
               <p>9.2您承認並同意，您對您在我們的服務上或通過我們的服務提供的所有用戶內容全權負責。因此，您聲明並保證：您是您在我們的服務上或通過我們的服務提供的所有用戶內容的唯壹和獨家所有者，或者您擁有授予科樂賽思本條款項下此類用戶內容權利所需的所有權利、許可、同意和發布；用戶內容以及您在我們的服務上、通過我們的服務或通過我們的方式張貼、上傳、發布、提交或傳輸用戶內容或科樂賽思對用戶內容（或其任何部分）的使用均不會侵犯、挪用或侵犯第三方的知識産權、公開權或隱私權，或導致違反任何適用的法律或法規。</p>
               <h3>10.禁止侵權使用。</h3>
               <p>您不會使用我們的服務提供、展示、分發、傳輸、路由、連接或存儲任何侵犯版權作品或以其他方式侵犯、侵犯或促進侵犯或侵犯任何第三方知識産權的材料。</p>
               <h3>11.評分、聊天、評論和反饋</h3>
               <p>11.1您可以對我們的服務上或通過我們的服務提供的內容進行聊天和評論（“評論”）。科樂賽思建議您在留下此類評論時要謹慎行事，並作出良好判斷。我們壹般不會監控、審查或緩和您的評論。壹旦您在我們的服務上或通過我們的服務完成並提交了您的評論，您就不應該期望能夠返回並編輯您的評論。您也不應該期望我們代表您對您的評論進行修改或刪除。</p>
               <p>11.2您應該意識到，如果您的評論被視爲誹謗，您可能會對他人的聲譽損害承擔法律責任。在不限制這些條款的任何其他條款或條件的情況下，科樂賽思可以但沒有義務監督或審查評論，並否認與之相關的任何和所有責任。盡管有上述規定，科樂賽思保留自行決定刪除其認爲不恰當、不恰當或與本條款允許的在線活動不壹致的任何評論的權利。</p>
               <p>11.3我們歡迎並鼓勵您爲改進我們的服務提供反饋、意見和建議（“反饋”）。您可以通過發送電子郵件至我們提交反饋kf@colocess.com或通過我們的服務中的反饋系統提交。</p>
               <p>11.4您承認並同意，所有反饋將是科樂賽思的唯壹和排他性財産，您特此不可撤銷地將您在所有反饋中的所有權利、所有權和利益，包括但不限于其中的所有全球知識産權，轉讓給科樂賽思，並同意不可撤回地將其轉讓給科樂賽思。此外，我們沒有義務爲任何反饋提供補償。在科樂賽思的要求和費用下，您將簽署文件並采取科樂賽思可能合理要求的進壹步行動，以協助科樂賽思獲得、完善和維護反饋中的知識産權和其他法律保護。</p>
               <h3>12.虛擬物品和虛擬貨幣</h3>
               <p>12.1 科樂賽思擁有、許可或以其他方式有權使用出現或源自服務、科樂賽思應用程序或第三方應用程序的所有科樂賽思內容，包括虛擬物品（“虛擬物品”）和虛擬貨幣（“虛擬貨幣”）。虛擬物品和虛擬貨幣僅供您個人和娛樂使用，它們只能在科樂賽思應用程序或第三方應用程序中使用，並且沒有“真實世界”價值。通過購買或接收虛擬物品和虛擬貨幣，您所收到的只是根據這些條款或其他可能適用的條款在科樂賽思應用程序或第三方應用程序中使用它們的有限許可證；虛擬物品和虛擬貨幣不是您的個人財産，它們的所有權權益不會轉移給您。虛擬物品和虛擬貨幣的價格、數量和種類可能隨時發生變化，恕不另行通知。您收到的虛擬物品和虛擬貨幣也可能隨時更改或停止使用，恕不另行通知。</p>
               <p>12.2您不能出售或轉讓，或試圖出售或轉讓虛擬物品或虛擬貨幣，除非在允許的情況下，您可以在服務、科樂賽思應用程序和第三方應用程序中進行交換，那些不能用“現實世界”貨幣（“可交易物品”）購買其他可交易物品的虛擬物品和虛擬貨幣，只要沒有爲可交易物品支付或提供任何貨幣價值；嚴禁進行任何其他聲稱的或試圖進行的交換。虛擬物品和虛擬貨幣可能永遠不會被您兌換成“真實世界”的金錢、商品、商品、服務，或科樂賽思或任何其他人的任何貨幣價值。</p>
               <h3>13.訂單和付款</h3>
               <p>13.1您同意，如果您未滿18歲或您獲得服務的法定成年年齡，您只能在您的法定監護人的參與下付款，並且您表示您的法定代理人已經閱讀、理解並同意這些條款。</p>
               <p>13.2您可以使用“真實世界”的資金，根據這些條款從科樂賽思購買使用虛擬物品或虛擬貨幣的有限許可證，並且您同意所有此類購買都是最終的。如果您從科樂賽思訂購了虛擬物品或虛擬貨幣的許可證，但這些許可證在提供給您之前變得不可用，您唯壹的補救措施是向交易的支付處理器申請退還購買價格。您對虛擬物品或虛擬貨幣的有限許可證的訂單是爲使用這些虛擬物品或數字貨幣而提供的，如果接受，這些虛擬物品和數字貨幣將立即下載到您的科樂賽思帳戶中。您明確同意在接受訂單後立即提供虛擬物品和虛擬貨幣。</p>
               <p>13.3如果您是歐盟居民，並且您從我們那裏購買了虛擬物品和虛擬貨幣的有限許可證，您可能有權在購買之日起7個工作日內（“冷靜期”）退出此類購買；但是，如果我方在冷靜期結束前開始履約，則該提款權不適用。因此，您明確同意並理解，如果您向我們訂購虛擬物品或虛擬貨幣的有限許可證，您的提款權在接受後即被沒收，因爲您的提款將在接受後立即開始。您理解並同意科樂賽思不爲任何購買提供退款，除非本條款中明確規定。</p>
               <h3>14.稅款</h3>
               <p>您負責並將支付您或任何使用向您注冊的科樂賽思賬戶的人所産生的所有費用和適用稅款。</p>
               <h3>15.移動、案頭操作軟件提供商和協力廠商出版商</h3>
               <p>15.1移動案頭設備操作軟件提供商（“作業系統提供商”）提供虛擬店面和市場，供您瀏覽、定位和下載移動或案頭應用程序等。 如果您從作業系統提供商運營的虛擬店面或市場下載科樂賽思應用程序或協力廠商應用程序，請注意，除了遵守這些條款（以及任何適用的協力廠商發行商的條款和條件）外，您還必須遵守此類虛擬店面或市場的條款和細則，例如穀歌的Android市場。</p>
               <p>15.2關於協力廠商發行商，儘管科樂賽思不是您與任何協力廠商發佈商就協力廠商應用程序達成的任何授權合約的一方，但您承認並同意科樂賽思是每一個此類協力廠商申請程式的使用者授權合約的協力廠商受益人。 您還同意，一旦您接受任何此類協力廠商應用程序的許可條款和條件，科樂賽思作為協力廠商受益人，將有權（並將被視為已接受該權利）對您強制執行此類許可。</p>
               <h3>16.用戶之間的互動</h3>
               <p>16.1您全權負責與其他科樂賽思用戶的互動（包括任何爭議）。 即使我們選擇在我們的服務中提供報告用戶、封锁用戶或類似功能，您仍將全權負責，並且在使用我們的服務和向其他科樂賽思用戶披露個人資訊時必須謹慎、謹慎、常識和判斷。 你同意採取合理的措施在與其他科樂賽思用戶的所有互動中的注意事項，特別是當您决定離線或親自與科樂賽思用戶會面時。 您使用我們的服務、科樂賽思內容、用戶內容和通過我們的服務提供的任何其他內容的風險和自由裁量權由您自行承擔，科樂賽思特此不對您或任何協力廠商承擔任何責任。</p>
               <p>16.2 科樂賽思保留根據適用法律聯系會員的權利，以評估是否遵守這些條款和任何其他適用規則。 您將與科樂賽思充分合作，調查任何涉嫌非法、欺詐或不當活動，包括但不限於授權科樂賽思代表訪問您的科樂賽思帳戶中受密碼保護的部分。</p>
               <h3>17.一般禁令</h3>
               <p>17.1您同意在使用我們的服務、訪問內容或用戶內容時不做以下任何事情：</p>
               <ul>
                 <li><p>17.1.1張貼、上傳、發佈、提交或傳輸任何文字、圖形、影像、軟件、音樂、音訊、視頻、資訊或其他資料：侵犯、挪用或侵犯協力廠商的知識產權、公開權或隱私權；</p></li>
                 <li><p>17.1.2違反或鼓勵任何可能違反任何適用法律或法規或可能引起民事責任的行為； 具有欺詐性、虛假性、誤導性或欺騙性； 具有誹謗性、淫穢、色情、粗俗或冒犯性； 宣揚對任何個人或群體的歧視、偏執、種族主義、仇恨、騷擾或傷害； 具有暴力或威脅性，或宣揚暴力或威脅他人的行為； 或促進非法或有害活動或物質（包括但不限於促進或提供有關製造或購買非法武器或非法物質的指導資訊的活動）。</p></li>
                 <li><p>17.1.3未經科樂賽思明確書面同意，使用、展示、鏡像、裝幀或利用裝幀科技將我們的服務或我們服務中的任何單個元素或資料、科樂賽思的名稱、任何科樂賽思或科樂賽思許可人的商標、徽標或其他專有信息、任何文字的內容或頁面上包含的任何頁面或表格的佈局和設計封裝起來；</p></li>
                 <li><p>17.1.4訪問、篡改或使用我們服務的非公共區域、科樂賽思的電腦系統或科樂賽思提供商的科技交付系統；</p></li>
                 <li><p>17.1.5試圖探測、掃描或測試任何科樂賽思系統或網絡的漏洞，或違反任何安全或身份驗證措施；</p></li>
                 <li><p>17.1.6避免、繞過、删除、停用、損害、解密或以其他管道規避科樂賽思或科樂賽思的任何提供商或任何其他協力廠商（包括另一科樂賽思用戶）為保護我們的服務或科樂賽思內容而實施的任何科技措施；</p></li>
                 <li><p>17.1.7試圖通過使用任何引擎、軟件、工具、代理訪問或蒐索我們的服務或科樂賽思內容，或從我們的服務下載科樂賽思内容， 科樂賽思或其他普遍可用的協力廠商網絡瀏覽器（如Google Chrome、Microsoft Internet Explorer、Mozilla Firefox、Apple Safari或Opera）提供的軟件和/或蒐索代理之外的設備或機制（包括蜘蛛、機器人、爬蟲、數據挖掘工具等）；</p></li>
                 <li><p>17.1.8發送任何未經請求或授權的廣告、促銷資料、電子郵件、垃圾郵件、垃圾郵件、連鎖信或其他形式的招攬；</p></li>
                 <li><p>17.1.9未經科樂賽思明確書面同意，利用科樂賽思或科樂賽思許可人的商標、徽標URL或產品名稱使用任何元標籤或其他隱藏文字或中繼資料；</p></li>
                 <li><p>17.1.10將我們的服務或科樂賽思內容用於任何商業目的或任何協力廠商的利益，或以本條款不允許的任何管道使用；</p></li>
                 <li><p>17.1.11在任何電子郵件或新聞組張貼中偽造任何TCP/IP數据包報頭或報頭資訊的任何部分，或以任何管道偽造我們的服務或科樂賽思內容，以發送更改的、欺騙性的或虛假的來源識別資訊；</p></li>
                 <li><p>17.1.12試圖解密、反編譯、反彙編或反向工程任何用於提供我們的服務或內容的軟件； 干擾或試圖干擾任何用戶、主機或網絡的訪問，包括但不限於發送病毒、超載、氾濫、垃圾郵件或郵件轟炸我們的服務；</p></li>
                 <li><p>17.1.13未經我們服務的其他用戶明確許可，從他們那裡收集或存儲任何個人身份資訊； 冒充或歪曲您與任何個人或實體的關係；</p></li>
                 <li><p>17.1.14違反任何適用的法律或法規； 鼓勵或允許任何其他個人做上述任何事情；</p></li>
                 <li><p>17.1.15發佈用戶內容或採取任何侵犯或侵犯另一會員權利的行動：欺淩、騷擾或恐嚇任何服務會員； 向另一會員索取會員登錄憑證，或收集用戶內容，或通過自動化管道訪問服務，包括但不限於機器人、機器人、蜘蛛；</p></li>
                 <li><p>17.1.16為您以外的任何人創建科樂賽思帳戶；</p></li>
                 <li><p>17.1.17利用您的會員檔案獲取個人商業利益；</p></li>
                 <li><p>17.1.18使用欺騙、漏洞利用、駭客攻擊、機器人程式、MOD或協力廠商軟件，旨在獲得相對於其他會員的優勢，無論是感知的還是實際的，或修改或干擾服務；</p></li>
                 <li><p>17.1.19濫用或利用服務中的漏洞、故障或機制； 或從事任何欺詐行為，包括但不限於信用卡詐騙或挪用信用卡。</p></li>
                 <li><p>17.1.20在官方科樂賽思論壇上發佈垃圾郵件或轉發删除的內容。 這包括但不限於多次發佈相同內容，或轉發已從論壇中删除的帖子或主題。</p></li>
                 <li><p>17.1.21不體育行為。 帳戶共亯，包括但不限於共亯用戶名和密碼，供他人代為登入。</p></li>
               </ul>
               <p>17.2 科樂賽思有權在法律的最大範圍內調查和起訴違反上述任何一項的行為，包括侵犯智慧財產權和服務安全問題。 科樂賽思可能會與執法部門合作，起訴違反這些條款的用戶。</p>
               <p>17.3 您承認科樂賽思沒有義務監控或記錄您對我們的服務或科樂賽思內容的訪問或使用，也沒有義務監控、記錄或編輯任何用戶內容，但同意我們有權這樣做是為了運營我們的服務，確保您遵守這些條款，或遵守適用法律或法院的命令或要求， 行政機構或其他政府機構。 您承認並同意，您對任何用戶內容的上傳、傳輸或提交不存在任何隱私期望。</p>
               <p>17.4 科樂賽思保留在任何時候删除或禁止訪問科樂賽思自行認為違反本條款或對我們的服務有害的任何用戶內容的權利，恕不另行通知。 我們鼓勵科樂賽思用戶向我們發送電子郵件，舉報任何涉嫌不當行為或濫用我們服務的行為 kf@colocess.com</p>
               <h3>18.連結和協力廠商資料連結和第二方資料連結及協力廠商資料</h3>
               <p>18.1我們的服務可能包含指向協力廠商網站或資源的連結。 您承認並同意科樂賽思不對以下內容負責：（i）此類網站或資源的可用性或準確性； 或（ii）此類網站或資源上的內容、產品或服務，或可從這些網站或資源獲得的內容、商品或服務。 到此類網站或資源的連結並不意味著科樂賽思對此類網站、資源或此類網站或來源的內容、產品或服務的任何認可。</p>
               <p>18.2您承認對您使用任何此類網站或資源所產生的所有風險承擔全部責任。 如果您選擇在移動、案頭運營商網絡上使用我們的服務，將適用該運營商的正常計費率。 此外，通過我們的服務提供的某些內容、產品和服務可能包括來自協力廠商的資料，包括協力廠商應用程序。</p>
               <p>18.3您同意科樂賽思不負責檢查或評估協力廠商內容或此類內容的準確性，科樂賽思對任何協力廠商應用程序或協力廠商的任何其他資料、產品或服務不保證也不承擔任何責任。</p>
               <p>18.4您同意，您不會以侵犯或侵犯任何其他方權利的管道使用任何協力廠商應用程序或其他協力廠商資料，科樂賽思對您的任何此類使用不承擔任何責任。</p>
               <h3>19.抽獎和競賽</h3>
               <p>科樂賽思可允許通過服務提供抽獎、競賽和類似促銷（統稱為“促銷”）。 您應仔細審查您通過服務參與的每個促銷活動的規則（如“官方規則”），因為這些規則可能包含關於科樂賽思對您作為促銷活動的一部分以及您參與此類促銷活動所提交的資料的權利和所有權的其他重要資訊。 如果此類官方規則的條款和條件與本條款相衝突，則以此類官方規則中的條款和條款為准。</p>
               <h3>20.遺產帳戶的終止</h3>
               <p>20.1在不限制其他補救措施的情况下，如果科樂賽思懷疑或自行决定您可能存在或存在以下重大風險，科樂賽思可隨時暫停或終止您的科樂賽思帳戶，並拒絕提供我們的服務：（i）未遵守本條款的任何規定或科樂賽思製定的任何政策或規則； （ii）參與與我們的服務有關或在使用我們的服務的過程中可能是非法的行為，或對您、科樂賽思用戶、科樂賽思或任何其他協力廠商或我們的服務造成責任、傷害、尷尬、騷擾、濫用或干擾； 或（iii）侵犯任何人的所有權、隱私權或知識產權，包括作為重複侵權人。</p>
               <p>20.2此外，如果發生上述任何情况，科樂賽思可以通知當局或採取其認為適當的任何其他行動，而無需通知您。 您可以隨時以任何理由終止您的科樂賽思帳戶，方法是發送電子郵件至 kf@colocess.com</p>
               <h3>21.帳戶終止、暫停的影響</h3>
               <p>21.1一旦您或我們因任何原因終止您的科樂賽思帳戶，您將失去對該帳戶的所有存取權限。 終止的科樂賽思帳戶無法恢復； 您在科樂賽思帳戶終止後可能注册的任何科樂賽思帳戶都是唯一的帳戶。</p>
               <p>21.2如果您或我們出於任何原因終止了您的科樂賽思帳戶，您理解並同意，在終止時您通過科樂賽思帳戶訪問的任何虛擬物品都將遺失，您將不再可以使用，您也無權使用這些虛擬物品。 如果您或我們出於任何原因終止您的科樂賽思帳戶，您理解並同意科樂賽思可以在終止時出於其選擇的任何目的贖回和使用科樂賽思帳戶中的虛擬貨幣，並且在終止時您將無權使用該虛擬貨幣。 此外，如果您的科樂賽思帳戶（非活動帳戶）已有3年沒有任何活動，您理解並同意（i）科樂賽思可以隨時出於其選擇的任何目的兌換和使用非活動帳戶中的虛擬貨幣，並且在該帳戶成為非活動帳戶時，您將無權使用該虛擬貨幣； 以及（ii）您通過非活動帳戶訪問的任何虛擬項目，直到該帳戶成為非活動帳戶，都將遺失，您將不再可以使用這些虛擬項目，並且您將無權使用這些項目。</p>
               <p>21.3您同意，無論出於何種原因，科樂賽思都不需要退款，並且您不會因非活動帳戶或終止科樂賽思帳戶中未使用的虛擬物品或虛擬貨幣而獲得金錢或其他補償，無論這兩者是如何產生的。 在任何終止之後，您理解並承認，我們將不再有義務提供我們的服務，這些條款授予您的所有許可證和其他權利將立即停止。 科樂賽思對您或任何協力廠商終止我們的服務或終止您使用我們的服務不承擔任何責任。</p>
               <p>21.4在您的主訪問帳戶終止或暫停後，您在我們的服務上提交的任何內容、資料或資訊（包括用戶內容）或與您的主帳戶相關的內容、資料和資訊可能不再被您訪問。 此外，科樂賽思沒有義務維護我們資料庫中存儲的與您的科樂賽思帳戶有關的任何資訊，也沒有義務將任何資訊轉發給您或任何協力廠商。 任何暫停、終止或取消都不會影響您在這些條款下對科樂賽思的義務（包括但不限於所有權和所有權、賠償和責任限制），根據其意義和上下文，這些義務將在暫停、終止和取消後繼續有效。</p>
               <h3>22.免責聲明</h3>
               <p>我們的服務、訪問內容和用戶內容都是按原樣提供的，沒有任何明示或暗示的保證。 在不限制上述規定的情况下，COLOCESS明確否認任何關於適銷性、特定用途的適用性、安靜享受或不侵權的保證，以及交易過程或貿易使用過程中產生的任何保證。 Coloss不保證我們的服務、Coloss內容或用戶內容將滿足您的要求，或在不間斷、安全或無錯誤的基礎上提供。 對於通過我們的服務購買或獲得的任何產品、服務或內容的質量，或通過我們服務獲得的任何內容的準確性、及時性、真實性、完整性或可靠性，COLOCESS不作任何保證。 從Coloss或通過我們的服務、Coloss內容或用戶內容獲得的任何建議或資訊，無論是口頭還是書面，都不會產生本協定未明確規定的任何保證。</p>
               <h3>23.賠償</h3>
               <p>您同意保護、賠償並保護Colocess及其高級職員、董事、員工和代理人免受因您提交給Colocess的用戶內容、您對我們的服務或Colocess內容的訪問而產生的或以任何管道與之相關的任何索賠、責任、損害、損失和費用，包括但不限於合理的法律和會計費用， 或您違反這些條款。</p>
               <h3>24.責任限制</h3>
               <p>您承認並同意，在法律允許的最大範圍內，您訪問和使用我們的服務、訪問內容和其中的用戶內容所產生的全部風險仍由您承擔。 COLOCESS或參與創建、生產或交付我們的服務或COLOCESS內容的任何其他方均不對任何附帶、特殊、懲戒或後果性損害負責，包括利潤損失、數據損失或商譽損失、服務中斷、電腦損壞或系統故障或替代產品或服務的成本，由這些條款引起或與之相關，或由使用或無法使用我們的服務或其中的內容引起，無論是基於保證、契约、侵權行為（包括疏忽）、產品責任還是任何其他法律理論， 以及是否已告知COLOCESS發生此類損害的可能性，即使本協議規定的有限補救措施未能達到其基本目的。 您明確承認Colosse不對其他用戶或協力廠商的誹謗、冒犯或非法行為，上述行為造成的傷害風險完全由您承擔。 此外，對於上傳到我們的服務上或從我們的服務下載的任何用戶內容或協力廠商內容，COLOCESS對您或任何協力廠商不承擔任何責任。 在任何情况下，COLOCESS的總負債都不會因這些而產生或與之相關使用或無法使用我們的服務或訪問其中的內容或用戶內容，或由此提供的服務或內容超過一百美元（100美元）。 上述損害賠償的限制是科洛塞斯與您之間交易基礎的基本要素。 某些司法管轄區不允許排除或限制間接或附帶損害的責任，囙此上述限制可能不適用於您。</p>
               <h3>25.商標和其他所有權聲明</h3>
               <p>Colocess或我們的許可方在與服務相關的情况下使用的所有商標、服務標記、徽標、商品名和任何其他專有名稱均為Coloc塞斯或我們的授權方的商標或注冊商標。 任何其他商標、服務標誌、徽標、商品名和任何其他專有名稱均為其各自所有者的商標或注冊商標。</p>
               <h3>26.控制法律和管轄權</h3>
               <p>本條款及與之相關的任何行動均受中華人民共和國法律管轄，不考慮其法律規定的衝突。 因本條款引起的或與本條款有關的任何爭議應提交上海國際經濟貿易仲裁委員會/上海國際仲裁中心（“SHIAC”）進行仲裁，仲裁應按照申請仲裁時有效的SHIAC仲裁規則進行。 仲裁地點應為中國上海。 仲裁程式使用的語言應為中文。 仲裁裁决是終局的，對雙方均有約束力。</p>
               <h3>27.完整協定</h3>
               <p>這些條款構成Colocess和您之間關於我們的服務和Colocess內容的完整和排他性的理解和協定，並且這些條款取代和取代Colocess與您之間之前關於我們的服務和Colocess內容的任何和所有口頭或書面理解或協定。</p>
               <h3>28.轉讓</h3>
               <p>未經Colocess事先書面同意，您不得通過法律或其他管道轉讓或轉讓這些條款。 未經您同意，您轉讓或轉讓本條款的任何嘗試均無效。 Colocess可以自由轉讓這些條款。 在符合上述規定的情况下，本條款將對雙方、其繼承人和許可受讓人具有約束力，並使其受益。</p>
               <h3>29.通知</h3>
               <p>29.1您同意使用：（i）電子管道完成本條款，並交付本條款允許或要求的任何通知或其他通信； 以及（ii）存儲與本條款或您使用我們的服務有關的資訊的電子記錄。</p>
               <p>29.2本協定項下允許或要求的任何通知或其他通信，包括與本條款修改有關的通知或通信，均應以書面形式發出：（i）Colocess通過電子郵件（在任何情况下均發送至您提供的地址）或（ii）在我們的服務上或通過我們的服務張貼。 對於通過電子郵件發出的通知，接收日期將被視為該通知的發送日期。</p>
               <h3>30.概述</h3>
               <p>30.1 Colocess未能執行本條款中的任何權利或規定，不構成對未來執行該權利或規定的放弃。 對任何此類權利或規定的棄權只有以書面形式並由Colocess的正式授權代表簽字方可生效。</p>
               <p>30.2除非本條款另有明確規定，否則任何一方行使本條款項下的任何補救措施均不影響其在本條款或其他方面的其他補救措施。 如果出於任何原因，具有管轄權的法院認定本條款的任何條款無效或不可執行，則該條款將在允許的最大範圍內執行，而本條款的其他條款仍將完全有效。</p>
               <p>30.3您同意：（i）本條款適用於Colocess Technology Co.，Ltd（“關聯出版商”）的任何協力廠商出版商； （ii）除非根據任何協力廠商條款與任何此類關聯出版商另有約定，否則您在這些條款下對Colocess的義務適用於關聯出版商； 以及（iii）關聯出版商是本條款項下的協力廠商受益人，他們可能依賴並直接對您執行本條款。</p>
               <h3>31.聯繫我們</h3>
               <p>如果您對這些條款有任何疑問，請聯系Colocess，網址為 kf@colocess.com</p>
               `,
    },
    // 隐私协议
    duty: {
      title: "科樂賽思隱私政策",
      content: "引言",
      footer: "成都科乐赛思有限公司",
      contentOne: `
                <p>如果您使用Colocess Applications版本，而不是在中國大陸、日本和韓國等特定國家/地區分發的版本，則您承認已閱讀並理解本隱私政策。如果您不同意此隱私政策（“此政策”），則不得使用科樂賽思服務條款。如果您將來改變主意，您可以根據本政策撤回對使用您的個人信息的同意。您同意，如果您未滿18歲或您獲得服務的法定成年年齡，您表示您的法定監護人已閱讀、理解並同意本政策。</p>
                <p>在科樂賽思，我們知道您的隱私對您非常重要，所以請花時間仔細閱讀我們的隱私政策。我們的政策旨在幫助您理解</p>
                <p>1、 我們收集了哪些關于您的信息；</p>
                <p>2、 我們將如何使用有關您的信息；</p>
                <p>3、 我們與誰共享您的信息；</p>
                <p>4、 我們掌握您的信息多長時間；</p>
                <p>5、 我如何行使對我的信息的權利；</p>
                <p>6、 爭議解決；</p>
                <p>7、 我們將如何通知您變更；</p>
                <p>8、 聯系信息；</p>
                <p>本隱私政策是科樂賽思科技有限公司提供的科樂賽思服務條款不可分割的壹部分。本政策適用于直接引用或鏈接本政策的所有科樂賽思服務條款，但不適用于具有不包含本政策的單獨隱私政策的科樂賽思服務條款</p>
                <h3>第壹節：我們是數據控制器</h3>
                <p>1.我們使用的個人信息類型</p>
                <p>本節介紹了我們收集的不同類型的個人信息以及我們如何收集這些信息。我們將收集和使用以下關于您的信息：</p>
                <p>1.1.您向我們提供的信息。</p>
                <ul>
                  <li><p>a） 當您注冊遊戲時（包括您指定的昵稱，或我們從您連接的社交媒體帳戶（微信、臉書、谷歌）導入的自定義帳戶和信息，以設置您的個人資料，包括您在社交媒體個人資料和個人資料圖片上顯示的姓名）；</p></li>
                  <li><p>b） 當您以“訪客”身份登錄以使用遊戲（您的昵稱）時；</p></li>
                  <li><p>c） 當您參與遊戲中的調查時（在這種情況下，我們將處理您作爲調查的壹部分提供給我們的信息）</p></li>
                  <li><p>d） 當您通過我們的服務（如適用）或與我們授權的第三方支付服務提供商進行交易時。</p></li>
                  <li><p>e） 當您根據提供商的政策將您的賬戶與您的社交媒體賬戶（微信、臉書、谷歌等）或其他外部賬戶鏈接或使用其他社交媒體功能時。</p></li>
                  <li><p>f） 當您使用我們的服務或通過我們的服務與其他用戶和/或我們互動時。這包括但不限于您與我們互動時可能生成的cookie。</p></li>
                </ul>
                <p>1.2.我們收集的有關您的信息。</p>
                <ul>
                   <li><p>a） 名稱；</p></li>
                   <li><p>b） 生成的用戶名和打開id；</p></li>
                   <li><p>c） 地址、電子郵件地址、電話號碼（“聯系信息”）；</p></li>
                   <li><p>d） 交易信息；</p></li>
                   <li><p>e） 年齡、性別、出生日期；</p></li>
                   <li><p>f） 照片；</p></li>
                   <li><p>g） 使用數據和交易數據；</p></li>
                   <li><p>h） 位置相關信息，包括地理位置和IP地址；</p></li>
                   <li><p>i） 調查內容和答複；</p></li>
                   <li><p>j） 廣告ID；</p></li>
                   <li><p>k） 客戶支持票證ID和用戶與支持的通信；</p></li>
                   <li><p>l） 遊戲統計（等級，分數/s）；</p></li>
                   <li><p>m） 設備信息（如應用程序版本、網絡類型、操作系統）；</p></li>
                   <li><p>n） 營銷和溝通數據，如您對接收營銷材料的偏好、您的溝通偏好以及您與我們和我們的服務提供商的溝通曆史。</p></li>
                   <li><p>o） 聊天數據；和</p></li>
                   <li><p>p） 安全相關信息。</p></li>
                </ul>
                <p>1.3.我們從合作夥伴那裏收集的數據。</p>
                <ul>
                  <li><p>a） 如果您將第三方工具與服務（如微信、臉書、谷歌）鏈接，我們將收到數據。如果您使用您的社交媒體賬戶（如微信、臉書、谷歌等）注冊爲我們服務的用戶，並將您的賬戶鏈接到您的社交社交媒體賬戶，我們可能會根據社交媒體賬戶提供商的政策訪問您自願提供給該提供商的有關信息，我們將根據本政策管理和使用這些個人數據。</p></li>
                  <li><p>b） 人口統計數據（例如確定Ip地址的粗略位置）</p></li>
                  <li><p>c） 打擊欺詐的數據（如遊戲中濫用退款或廣告中的點擊欺詐）</p></li>
                  <li><p>d） 來自遊戲運行平台的數據（例如驗證支付）</p></li>
                  <li><p>e） 用于廣告和分析目的的數據，因此我們可以爲您提供更好的服務</p></li>
                </ul>
                <p>1.4. Cookies.</p>
                <p>我們使用Cookie和其他類似技術（如網絡信標、日志文件、腳本和電子標簽）（“Cookie”）來增強您使用Colocess服務的體驗。Cookie是小文件，當它們放在您的設備上時，使我們能夠提供某些功能。您可以選擇允許安裝此類Cookie或隨後禁用它們。您可以接受所有cookie，或指示設備（在遊戲的情況下）在安裝cookie時發出通知，或通過調整設備中的相關cookie保留功能來拒絕接受所有cookie。但是，如果您拒絕安裝cookie，遊戲可能無法按設計運行。</p>
                <p>2.我們如何使用您的個人信息</p>
                <p>2.1我們將通過以下方式使用這些信息：</p>
                <ul>
                  <li><p>2.1.1根據您的請求爲遊戲創建您的帳戶；</p></li>
                  <li><p>2.1.2生成用戶名和打開id：將您的遊戲數據（包括級別和進度）與您的個人資料壹起存儲；</p></li>
                  <li><p>2.1.3通過電話、短信和/或傳真、電子郵件和/或郵政郵件或其他方式與您聯系或溝通，以管理和/或管理您與我們的關系或您對我們服務的使用；</p></li>
                  <li><p>2.1.4保存您的交易曆史記錄；</p></li>
                  <li><p>2.1.5管理、運營、提供和/或管理您對我們服務的使用和/或訪問（包括但不限于您的偏好），以及您與我們的關系和賬戶；</p></li>
                  <li><p>2.1.6用于識別和/或驗證；（如適用）</p></li>
                  <li><p>2.1.7進行研究、分析和開發活動（包括但不限于數據分析、調查、産品和服務開發和/或分析），分析您如何使用我們的服務，改進我們的服務或産品和/或增強您的客戶體驗；</p></li>
                  <li><p>2.1.8如果您啓用聊天服務（音頻或文本），我們將處理這些數據，以便將您的消息傳遞給其他用戶；</p></li>
                  <li><p>2.1.9出于安全和核查目的；識別和解決漏洞，評估遊戲功能以優化行動；解決遊戲崩潰並優化設備與遊戲的兼容性；打擊用戶注冊多個賬戶；</p></li>
                  <li><p>2.1.10允許其他用戶與您互動、交流或連接，包括在其他用戶通過遊戲或服務向您發送私人消息或發表評論時通知您；</p></li>
                  <li><p>2.1.11在您的管轄範圍內或管轄範圍外存儲、托管、備份您的個人數據（無論是用于災難恢複還是其他目的）；</p></li>
                  <li><p>2.1.12根據法律或監管義務或我們的風險管理程序進行盡職調查或其他篩選活動（包括但不限于背景調查），這些程序可能是法律要求的或我們已經實施的；</p></li>
                  <li><p>2.1.13響應法律程序或遵守任何相關司法管轄區的任何適用法律、政府或監管要求，包括但不限于滿足對我們或相關公司或附屬公司有約束力的任何法律要求下的披露要求；</p></li>
                  <li><p>2.1.14在適用法律允許的情況下，爲任何營銷活動進行營銷和廣告宣傳，並在這方面推薦與您的利益相關的産品和/或服務，通過各種媒介和模式（包括電子郵件或短信）向您發送與我們（和/或其附屬公司或相關公司）可能正在銷售、營銷或推廣的産品和/或服務（包括但不限于我們可能合作或合作的第三方的産品和（或）服務）有關的通信營銷和促銷信息和材料，無論這樣的産品或服務現在存在還是將來創建。您可以使用電子營銷材料中的取消訂閱功能隨時取消訂閱接收營銷信息。我們可能會使用您的聯系信息發送我們和相關公司的時事通訊或營銷材料；</p></li>
                  <li><p>2.1.15適用法律允許的其他目的，並且我們在獲得您的同意時通知您。</p></li>
                </ul>
                <p>此類目的可能並非如此，因爲我們收集/可能收集、使用、披露或處理您的個人數據的目的取決于當前情況。但是，除非隱私法允許在未經您同意的情況下處理適用數據，否則我們將在獲得您的同意時通知您其他目的。
                </p>
                <p>2.2分析、簡介和劃分。 </p>
                <p>在上述所有情況和目的下，我們可以分析、簡介和劃分所有收集的數據。</p>
                <p>2.3處理的法律依據</p>
                <p>如果您是歐盟居民，我們將根據許多法律依據來處理有關您的信息。例如，我們將在征得您同意的情況下，在我們有合法利益的情況下處理您的信息，在履行與您的合同所需的情況下進行處理，以及在我們有法律義務處理您信息的情況下對您的信息進行處理。例如，我們依靠我們的合法利益來服務于有針對性的營銷，以及在您創建帳戶時處理信息的合同必要性。要了解更多關于我們出于任何特定目的處理您的信息所依據的法律依據，請通過下面的聯系和投訴中的選項與我們聯系。</p>
                <p>3.我們如何共享您的個人信息</p>
                <p>3.1共享。我們重視對您個人信息的保護，這是我們爲您提供産品和服務的重要基礎和組成部分。我們只會出于本政策的目的和範圍內或根據法律法規的要求收集和使用您的個人信息，並對其嚴格保密。壹般情況下，我們不會與任何公司、組織或個人共享您的個人信息，以下情況除外：</p>
                <ul>
                  <li><p>（a） 事先征得您的同意；</p></li>
                  <li><p>（b） 適用法律、法規、法律程序、政府強制或司法決定要求共享您的個人信息；</p></li>
                  <li><p>（c） 在法律要求或允許的範圍內，有必要向第三方提供您的個人信息，以保護我們、其用戶或公衆免受其利益、財産或安全的損害；</p></li>
                  <li><p>（d） 您的個人信息可能會在我們的附屬公司之間共享。我們只會分享必要的個人信息，此類分享受本政策所述目的的約束。如果附屬機構希望更改處理個人信息的目的，它將再次征求您的授權同意；</p></li>
                  <li><p>（e） 爲了向您提供改進的、高質量的産品和服務，我們的部分服務將由授權合作夥伴提供。我們可能會與合作夥伴共享您的壹些個人信息，以提供更好的客戶服務和用戶體驗。我們只會出于合法、合法、必要、具體和明確的目的分享您的個人信息，並且只會分享提供服務所需的個人信息。我們還要求我們的合作夥伴根據我們的指示、本政策以及任何其他相關的保密和安全措施處理您的個人信息。我們的合作夥伴無權將共享的個人信息用于任何其他目的。如果您拒絕允許我們的合作夥伴收集提供服務所需的個人信息，您可能無法在我們的平台上使用該第三方服務。</p></li>
                </ul>
                <p>我們的合作夥伴收集和處理信息受其自己的隱私政策或相關聲明的約束，這些政策不適用于本政策。爲了最大限度地保護您的信息安全，我們建議您在使用任何第三方服務之前審查其隱私政策。爲了保護您的合法權益，如果您發現相關第三方的服務存在風險，我們建議妳立即終止相關操作並及時與我們聯系。</p>
                <p>我們目前有權訪問以下第三方合作夥伴權限：https://www.Colocess.com/3rdsdk?locale=en-US</p>
                <p>3.2轉讓。我們不會將您的個人信息轉移給任何公司、組織或個人，以下情況除外：</p>
                <ul>
                  <li><p>a） 在獲得明確同意的情況下轉移：在獲得您的明確同意後，我們將把您的個人信息轉移給其他方；</p></li>
                  <li><p>b） 根據適用法律法規、法律訴訟要求以及必要的強制性行政或司法要求提供；</p></li>
                  <li><p>c） 如果發生涉及個人信息轉讓的合並、收購、資産轉讓、破産和清算或類似交易，我們將要求持有您個人信息的新公司或組織繼續受本政策約束，然後再要求該公司或組織再次征求您的授權同意。</p></li>
                </ul>
                <p>3.3披露。我們只會在以下情況下公開披露您的個人信息：</p>
                <ul>
                  <li><p>a） 在獲得您的明確同意後；</p></li>
                  <li><p>b） 基于法律的披露：如果法律、法律程序、訴訟或政府當局強制要求，我們可能會公開披露您的個人信息。</p></li>
                </ul>
                <p>3.4未經您事先同意。根據相關法律法規和國家標准，在以下情況下，我們可以在未經您事先授權同意的情況下共享、轉移和公開披露個人信息：</p>
                <ul>
                  <li><p>a） 與我們履行法律法規規定的義務有關；</p></li>
                  <li><p>b） 直接關系到國家安全和國防安全；</p></li>
                  <li><p>c） 與公共安全、公共衛生和重大公共利益直接相關的；</p></li>
                  <li><p>d） 與犯罪偵查、起訴、審判、執行判決等直接相關的；</p></li>
                  <li><p>e） 爲維護個人信息主體或其他個人的生命、財産和其他重大合法權益，但難以征得其同意的；</p></li>
                  <li><p>f） 個人信息主體本人向公衆披露的個人信息；</p></li>
                  <li><p>g） 從合法公開披露的信息中收集的個人信息，如法律新聞報道、政府信息披露和其他渠道。</p></li>
                </ul>
                <p>根據法律規定，在數據接收方無法恢複和重新識別信息主體的情況下，共享、轉移或公開披露已被取消身份的個人信息不應被視爲共享、轉移和公開披露個人信息，此類數據應在未通知您或未經您同意的情況下進行存儲和處理。</p>
                <p>4.數據保留</p>
                <p>4.1我們將按照以下說明保留您的個人信息（除非適用法律另有要求）。</p>
                <div class="table" style="width: 1300px; margin: 30px auto;">
                <table align="center" style="width: 100%; border: 1px solid black; border-collapse: collapse; font-size:14px; padding:20px;">
                    <tbody style="height: 50px; ">
                       <th style="border: 1px solid black; width: 45%;">個人信息</th>
                       <th style="border: 1px solid black; width: 55%;">保留策略</th>
                    </tbody>
                    <tr>
                      <th style="border: 1px solid black; width: 45%; padding:20px;">昵稱</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲（即，直到根據您的請求刪除帳戶爲止），然後在30天內刪除。</th>
                    </tr>
                    <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">社交連接信息：微信</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲（即，直到根據您的請求刪除帳戶或您撤銷對社交媒體帳戶的訪問），然後在30天內刪除。</th>
                    </tr>
                    <tr>
                    <th style="border: 1px solid black; width: 45%;padding:20px;">社交連接信息：Facebook</th>
                    <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到帳戶應您的要求被刪除，之後它將在15天內被刪除。如果您不要求刪除帳戶，此數據將在遊戲關閉或我們不再使用此數據後120天內刪除。</th>
                    </tr>
                    <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">遊戲統計數據（等級，分數/s）</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊求刪除帳戶，則在遊戲關閉後刪除之前，這些數據將保留1年。戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，然後將在30天內刪除。如果您沒有請</th>
                    </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">聊天通信（音頻信息）</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在我們的服務器上存儲30天，到期後自動刪除。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">聊天通信（文本）</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，帳戶將在7天內刪除。如果您沒有請求刪除帳戶，則在遊戲關閉後刪除之前，這些數據將保留1年。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">OpenID</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，之後將在15天內刪除。如果您沒有請求刪除帳戶，則在遊戲關閉後刪除之前，這些數據將保留1年。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">IP地址</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，之後將在15天內刪除。如果您沒有請求刪除帳戶，則在遊戲關閉後刪除之前，這些數據將保留1年。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">設備ID，設備信息</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，然後將在30天內刪除。如果您沒有請求刪除帳戶，則在遊戲關閉後刪除之前，這些數據將保留1年。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">廣告ID</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，之後將在15天內刪除。如果您沒有請求刪除帳戶，則在遊戲關閉後刪除之前，這些數據將保留1年。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">交易記錄</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲（即，直到根據您的請求刪除帳戶爲止），然後在爲遵守我們的法律義務而必須保留此類數據的任何保留期內存儲。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">客戶支持票證ID和用戶與支持的通信</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">支持數據將在您提出刪除此類數據的合法請求後30天內刪除（請參閱本隱私政策的“刪除”部分）。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">安全相關信息</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用遊戲的整個生命周期內存儲，直到根據您的請求刪除帳戶，然後將在30天內刪除。</th>
                   </tr>
                   <tr>
                      <th style="border: 1px solid black; width: 45%;padding:20px;">調查信息和調查回複的內容</th>
                      <th style="border: 1px solid black; width: 55%;padding:20px;">存储期限：（a）如果您将电子邮件作为调查的一部分提供，最长6个月（以便我们跟进您），然后数据将被匿名化；或者（b）如果您没有将电子邮件作为调查的一部分提供，最长为1个月，然后数据被匿名化。</th>
                   </tr>
                </table>
                </div>
                <p>以上列出的數據刪除時間僅基于我們遊戲客戶端本身收集的信息，如果我們從其他合作夥伴（如App Store、Google Play、Facebook等）獲得信息，相應的時間可能會發生變化，具體時間以披露的合作夥伴的隱私政策爲准。</p>
                <p>4.2個人信息將壹直保存到其生命周期結束（如上述保留政策所述）。在銷毀個人信息時，將采取措施使個人信息不可恢複或不可複制，並使用使文件不可複制的技術方法永久刪除包含個人信息的電子文件。</p>
                <p>4.3如果處理和保留期終止，但由于其他原因，包括適用法律規定的目的，個人信息需要持續保留，相關個人信息將與其他類型的個人信息分開存儲和維護。如果您要求我們在您的個人信息生命周期結束前銷毀您的個人資料（如上述保留政策所述），我們將根據當地法律銷毀您的私人信息。</p>
                <h3>第二節：妳的權利</h3>
                <p>5.本節（“您的權利”）僅適用于位于歐洲經濟區的用戶。對于位于其他地區的用戶，請參閱本政策中的隱私條款和Colocess服務條款。</p>
                <p>您對我們持有的有關您的個人信息享有某些權利。其中壹些僅適用于某些情況（如下所述）。我們必須響應您的請求，在不無故拖延的情況下，至少在壹個月內行使這些權利（盡管在某些情況下可能會再延長兩個月）。要行使您的任何權利，請發送電子郵件至kf@colocess.com.</p>
                <p>5.1權力</p>
                <p>您有權訪問我們持有的關于您的個人信息、我們如何使用這些信息以及我們與誰共享這些信息。您可以通過登錄您的遊戲帳戶並聯系來訪問您作爲帳戶壹部分提供的個人信息kf@colocess.com.如果您認爲我們持有您的任何其他個人信息，請發送電子郵件至kf@colocess.com.</p>
                <p>5.2便攜性</p>
                <p>您有權收到我們處理的有關您的某些個人信息的副本。這包括我們在您同意的基礎上或根據我們與您的合同處理的任何個人信息（例如，某些調查信息），如“我們如何使用您的個人信息”壹節所述。您有權以結構化、通用和機器可讀的格式接收此信息。您也有權要求我們將該個人信息轉移給另壹方。</p>
                <p>如果您希望我們將此類個人信息轉移給第三方，請確保在您的請求中詳細說明該方。請注意，我們只能在技術可行的情況下這樣做。請注意，如果提供個人信息會幹擾他人的權利（例如，提供我們持有的關于您的個人信息會泄露他人的信息或我們的商業秘密或知識産權），我們可能無法向您提供個人信息。</p>
                <p>5.3糾正</p>
                <p>您有權更正任何不准確的個人信息。您可以通過登錄您的遊戲帳戶來訪問您作爲帳戶壹部分提供的個人信息。如果您認爲我們持有關于您的任何其他個人信息，並且這些信息不准確，請發送電子郵件至kf.colocess.com。</p>
                <p>5.4刪除</p>
                <p>您可以通過登錄您的遊戲帳戶來刪除您的帳戶或刪除某些個人信息。如果您認爲我們處理的任何其他個人信息希望我們刪除，請發送電子郵件至kf.colocess.com。</p>
                <p>在以下情況下，您可以要求我們刪除您的個人信息：</p>
                <ul>
                  <li><p>a） 您認爲我們不再需要持有此類個人信息；</p></li>
                  <li><p>b） 您認爲我們持有的有關您的個人信息正被我們非法處理。</p></li>
                </ul>
                <p>在我們考慮您的請求時，您也可以行使您的權利，限制我們處理您的個人信息（如下所述）。</p>
                <p>如果數據保護法有正當理由（例如，爲了保護法律主張或言論自由），我們可能需要保留個人信息，但如果是這樣，我們會通知您。如果您要求我們刪除在遊戲服務上公開的個人信息，並且有理由刪除，我們將采取合理措施，試圖告訴其他顯示個人信息或提供個人信息鏈接的人也刪除這些信息。</p>
                <p>6.處理僅限于存儲</p>
                <p>在某些情況下，您有權要求我們停止處理我們持有的有關您的個人信息，而不是出于存儲目的。但是，請注意，如果我們停止處理個人信息，如果根據數據保護法有正當理由（例如，爲了辯護法律索賠或他人的保護），我們可能會再次使用它。如上所述，如果我們同意停止處理個人信息，我們將盡力告知我們已向其披露相關個人信息的任何第三方，以便他們也可以停止處理。</p>
                <p>您可以要求我們停止處理，只存儲我們持有的有關您的個人信息：</p>
                <ul>
                  <li><p>a） 您認爲個人信息在我們驗證其是否准確所需的時間內不准確</p></li>
                  <li><p>b） 您希望刪除個人信息，因爲我們正在進行的處理是非法的，但您希望我們保留個人信息，而只是存儲它；</p></li>
                  <li><p>c） 您希望刪除個人信息，因爲它不再是我們的目的所必需的，但您要求將其存儲起來以建立、行使或辯護法律索賠。</p></li>
                </ul>
                <p>7.反對</p>
                <p>您有權反對我們處理您的個人信息。在其他情況下，我們將考慮您的請求，具體如下，請發送電子郵件至kf.colocess.com。</p>
                <p>8.公告</p>
                <p>當我們認爲有必要時，我們可能會不時向您發送公告（例如，當我們出于維護、安全、隱私或行政相關通信的目的暫時暫停遊戲訪問時）。您不能選擇退出這些與服務相關的公告，這些公告本質上不是促銷性質的。</p>
                <h3>第3節：其他選項</h3>
                <p>9.信息安全</p>
                <p>我們已經實施了管理、物理和技術安全措施，旨在保護您的信息免受丟失、盜竊、濫用、未經授權的訪問、披露、更改和破壞。不過，妳應該明白，盡管我們做出了努力，但任何安全都無法保證如此無懈可擊。</p>
                <p>10.鏈接到他人運營的網站和服務</p>
                <p>科樂賽思服務條款可能包含指向其他網站、應用程序和服務的鏈接。由于科樂賽思不運營這些其他網站、應用程序和服務，我們不能對運營這些網站、應用軟件和服務的實體的隱私做法負責。我們建議您始終如壹地檢查隱私政策，以了解運營商的做法。作爲提醒，本政策描述了科樂賽思的做法。</p>
                <p>11.變更</p>
                <p>科樂賽思可能會不時更新此隱私策略。如果我們在收集、使用、保留或共享您的個人信息的方式上發生重大更改，我們將通過向您提供的最後壹個電子郵件地址發送電子郵件或發布本隱私政策所涵蓋服務的更改通知來通知您。在我們發布對本政策的修改或向您提供修改通知後繼續訪問或使用我們的服務，即表示您同意受修改條款的約束。如果您不能接受修改後的條款，您唯壹的追索權就是停止使用我們的服務。</p>
                <p>12.國際轉讓</p>
                <p>我們可能會將收集到的有關您的信息轉移給附屬實體，或跨境轉移給其他第三方，並從您的國家或管轄區轉移到世界其他國家或管轄權。請注意，這些國家和司法管轄區可能與您所在的司法管轄區沒有相同的數據保護法律，我們會采取措施確保有足夠的保障措施，以實現本政策中所述的信息傳輸、信息使用和披露，包括個人信息。</p>
                <p>13.聯系與投訴</p>
                <p>歡迎就這項政策提出問題、意見和要求。請通過電子郵件與我們聯系：kf.colocess.com。</p>
                <p>如果您想投訴我們如何處理您的個人信息，請首先通過kf.colocess.com我們將盡力盡快處理您的請求。這不影響您向您居住或工作所在國家的數據保護機構提出索賠的權利，您認爲我們違反了數據保護法。</p>
                <p>14.管轄權</p>
                <p>本政策及其相關行動將受中華人民共和國法律管轄，不考慮其法律規定的沖突。因本政策引起的或與本政策有關的任何爭議應提交上海國際經濟貿易仲裁委員會/上海國際仲裁中心（“SHIAC”）進行仲裁，仲裁應按照申請仲裁時有效的SHIAC仲裁規則進行。仲裁地點應爲中國上海。仲裁程序使用的語言應爲中文。仲裁裁決是終局的，對雙方均有約束力。</p>   
         `,
    },
    // 联系我们
    contactUs: {
      title: "聯系我們",
      content: "如果您對此遊戲有任何疑問、意見或建議。",
      contentOne: "請聯系我們：",
    },
  },
};


const topUp = {
  bundle:'礼包选择',
  instructions:'充值说明',
  products:'没有产品',
  BuyNum:'购买数量',
  Coupon:'优惠卷',
  PaymentMethod:'支付方式',
  logAccount:'请先登录游戏账号',
  logIn:'去登录',
  PurchaseDetails:'商品信息',
  Quantity:'商品数量',
  CharacterDetails:'角色信息',
  Price:'商品原价',
  Discount:'商城折扣',
  noDiscount:'无折扣',
  Coupon:'优惠券',
  cooperation:"官方合作",
  Contents:'礼包内容',
  SelectB:'请选择充值礼包',
  SelectP:'请选择支付方式',
  termofserviceTitle1:'TERMS OF USE',
  termofserviceTitle2:'PRIVACY POLICY',
  total:'合计',
  payText:'支付',
  Tips:'暂未开放',
  payType:['支付中','支付完成','支付中'],
  statuDON:'你已经成功充值',
  statuDTO:'消费',
  statuDTH:'充值游戏',
  statuDFO:'充值账号',
  statuDFI:'订单ID',
  statuDSI:'充值时间',
  orderList:'订单列表',
  location:"位置: 我的订单",
  search:"搜索"
}
const login = {
  "One": "账号登录",
  "Tow": "请输入账号",
  "Therr": "请输入密码",
  "Four": "请输入验证码",
  "Five": "登录",
  "Six": "第三方登录方式",
  "Seven": "邮箱登录",
  "Eight": "请输入邮箱",
  "Nine": "发送验证码",
  "Ten": "注册",
  "Eleven":"注册账号",
  "Twelve":"账号或密码错误",
  "thirteen": "验证码错误",
  "mistake":"请求出错",
  "registerSus":'注册成功',
  "loginSus":'登录成功',
  "sentSus":'发送成功',
  "phoneLogin":"手机号登录",
  "inPhone":"请输入手机号",
  "otherLogin":"其他登录方式",
  "pleaseCheck":'请阅读并同意 服务条款和隐私协议',
  "loginOut":'退出登录',
  "TermsService": "服务条款",
  "PrivacyAgreement": "隐私协议",
}
const l = {
  one:'验证码登录',
  tow:'密码登录',
  therr:'输入邮箱',
  four:'请输入邮箱',
  five:'输入验证码',
  six:'请输入验证码',
  seven:'我已阅读并同意',
  Eight:'获取验证码',
  Nine:'',

}

export {
  l,
  messages,
  topUp,
  login
}