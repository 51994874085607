import { mapState } from "vuex";
import md5 from "js-md5";
import sdk from "@/mixins/module/sdk";

export default {
  mixins:[sdk],
  data() {
    return {
      date: [],
      reqLoading: false,
      tabHeader: [
    
        // {
        //   label: 'product_name',
        //   prop: 'product_name',
        //   width:150
        // },
        {
          label: 'order_id',
          prop: 'order_id',
          width:360
        },
        {
          label: 'serial_number',
          prop: 'serial_number',
          width:150,
        },
        {
          label: 'pay_amount',
          prop: 'pay_amount',
          width:150,
        },
        {
          label: 'create_time',
          prop: 'create_time',
          width:170,
        },
        {
          label: 'pay_time',
          prop: 'pay_time',
          width:170,
        },
        {
          label: 'pay_status',
          prop: 'pay_status',
          width:150,
        },
        {
          label: 'client_ip',
          prop: 'client_ip',
          width:150,
        },
        {
          label: 'bank_code',
          prop: 'bank_code',
          width:150,
        },
        {
          label: 'payment_method',
          prop: 'payment_method',
          width:160,
        },
        {
          label: 'pay_way',
          prop: 'pay_way',
          width:150,
        }
      ],
      tableData: []
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.init.lang,
      userInfo: (state) => state.user.userInfo,
      productPrimitiveList: (state) => state.payment.productPrimitiveList,
    }),
  },
  async created() {
    this.getsearchDate()
  },
  watch: {},
  mounted() {},
  methods: {
    searchClick() {
      let data = {
        start_time: this.timeToTimestamp(this.date[0])  / 1000,
        end_time: this.timeToTimestamp(this.date[1]) / 1000,
      };
      this.reqLoading = true
      this.$api.openGameOrderStatus(data).then(async res=>{
        if(res.code == 0){
          // let list = res.data.map(i=>{return i.order_id})
          // let list = []

          // let reqorder =  this.getdevorders(['sdsadas','asdasdsa'])
          // console.log(reqorder)
          // if(reqorder.code == 200){
          //   list = res.data
          // }
          res.data.map(i=>{
            // let prIndex = list.findIndex(j=>j.order_id == i.order_id)
            // i.product_name = prIndex > -1 ? this.productPrimitiveList.find(k=>k.cp_product == list[prIndex].product).params.product_name : '',
            i.create_time = i.create_time ? this.$utils.formatDateTime(i.create_time,'YY-MM-DD HH:II:SS') : '/'
            i.pay_time = i.pay_time ? this.$utils.formatDateTime(i.pay_time,'YY-MM-DD HH:II:SS') : '/'
          })
          this.tableData = res.data
        }
      }).finally(()=>{
        this.reqLoading = false
      })
    },
    timeToTimestamp(time) {
      let timestamp = Date.parse(new Date(time).toString());
      return timestamp;
    },
    getsearchDate() {
      var now = new Date();
      var year = now.getFullYear();
      var month =
        (now.getMonth() + 1).toString().length < 2
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;

      var date =
        now.getDate().toString().length < 2
          ? "0" + now.getDate()
          : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.date[0] = date + " 00:00:00";
      this.date[1] = date + " 23:59:59";
    },
  },
};
