<template>
  <div class="optionPopup" v-if="isOptionPopup">
    <div class="optionPopupText">
      {{ text ? text : $t('topUp.Tips') }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOptionPopup:false,
      text:''
    }
  },
  created() {
  },
  computed: {

  },
  methods: {
    open(text){
      if(this.isOptionPopup){
        return
      }
      this.text = text
      this.isOptionPopup = true
      setTimeout(() => {
        this.isOptionPopup = false
      }, 1000);
    }
  }
}
</script>
<style lang="scss" scoped>
.optionPopup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .optionPopupText {
    font-size: 16px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 10px 20px;
  }
}
</style>