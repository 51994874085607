import { mapState } from "vuex";
import CryptoJS from "crypto-js";
import axios from "axios";
const Base64 = require("js-base64").Base64;

export default {
  data() {
    return {
      // 公用
      Configuration: {
        headers: {
          "sign-algorithm": "HMAC-SHA256",
          "sign-appid": "",
          "sign-timestamp": "",
          "sign-signature": "",
        },
        formData: {
          appid: "",
        },
        SecretKey: "",
        clientId: "868585049324-c8mcqgdhqoievrnkjb0ql29epq5k2h8o.apps.googleusercontent.com",
      },
      // 其他
      isLoading: false,
      // 接口列表
      reqFnList: {
        // 游客登录
        visitor: {
          url: "sdk/visitor",
          method: "post",
        },
        // 账号登录
        account: {
          url: "sdk/account-login",
          method: "post",
        },
        // 账号注册
        signIn: {
          url: "sdk/account-register",
          method: "post",
        },
        // 获取应用支付列表
        payTypeList: {
          url: "v1/pay-type-list",
          method: "get",
        },
        // 创建研发订单
        createOrder: {
          url: "v1/sdk/create-order",
          method: "post",
        },
        // 支付渠道预下单
        prepareOrder: {
          url: "v1/prepare-order",
          method: "post",
        },
        // 通过研发用户ID号获取订单列表
        getdeveloperorders: {
          url: "v1/get-developer-orders",
          method: "get",
        },
        // 通过研发订单ID号获取订单商品
        getdevorders: {
          url: "v1/get-dev-orders",
          method: "post",
        },
      },
      // 登录相关
      SDKURL: process.env.VUE_APP_SDKURL,
      fbSignInParams: {
        scope: "email,user_likes",
        return_scopes: true,
      },
      // 支付相关
      isPayreq: false,
      PAYURL: process.env.VUE_APP_PAYURL,
      payList: [],
      // 订单列表
      orderLists:[],
      // 订单商品列表
      orprList:[]
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.init.lang,
      gameData: (state) => state.payment.gameData,
      initData: (state) => state.init.initData,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  async created() {
    await this.getGameData();
    this.isPayreq && this.getAppPaylist();
  },
  watch: {},
  mounted() {},
  methods: {
    // 获取订单列表
    getdeveloperorders(){
      return new Promise((resolve, reject) => {
        let data = {
          appid:this.gameData.appid,
          developer_user_id:this.userInfo.developer_user_id
        }
        this.loginTyLogin(this.PAYURL,'getdeveloperorders',data).then(res=>{
          if(res.code == 200){
            this.orderLists = res.data
          }
          resolve(res)
        })
      })
    },
     // 获取订单商品
     getdevorders(developer_order_ids){
      return new Promise((resolve, reject) => {
        let data = {
          appid:this.gameData.appid.toString(),
          developer_order_ids:developer_order_ids
        }
        this.loginTyLogin(this.PAYURL,'getdevorders',data).then(res=>{
          if(res.code == 200){
            this.orprList = res.data
          }
          resolve(res)
        })
      })
    },
    /**
     * 支付相关
     *
     */
    // 获取支付方式列表
    getAppPaylist() {
      if (!this.gameData.appid) {
        return;
      }
      this.loginTyLogin(this.PAYURL, "payTypeList").then((res) => {
        if (res.code == 200) {
          this.payList = res.data;
        }
      });
    },
    // 创建研发订单
    sdkcreateOrder(data) {
      return new Promise((resolve, reject) => {
        this.loginTyLogin(this.PAYURL, "createOrder", data).then((res) => {
          if (res.code == 200) {
            this.loginTyLogin(this.PAYURL, "prepareOrder", { appid: data.appid, pay_order_id: res.data, pay_type_id: this.payList[this.payIndex].pay_type_id }).then((resd) => {
              if (resd.code == 200) {
                window.open(resd.data, "_blank");
              }
              resolve(resd)
            })
          }
        });
      });
    },
    // 随机生成设备ID
    guid() {
      return "xxxx-xxxx-4xxx-yxxx-xxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    getGameData() {
      return new Promise((resolve, reject) => {
        this.Configuration.headers["sign-appid"] = this.gameData.appid ? this.gameData.appid.toString() : "";
        this.Configuration.formData.appid = this.gameData.appid ? this.gameData.appid.toString() : "";
        this.Configuration.SecretKey = this.gameData.appsecret;
        resolve();
      });
    },
    // 接口请求
    loginTyLogin(reqUrl, url, queryData = {}) {
      let that = this;
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        let method = this.reqFnList[url].method;
        let timestamp = parseInt(new Date().getTime() / 1000);
        let data = JSON.parse(JSON.stringify(this.Configuration));
        data.headers["sign-timestamp"] = timestamp;
        this.timestamp = timestamp;
        let formData = {
          ...data.formData,
          ...queryData,
        };
        let str = data.headers["sign-algorithm"] + data.headers["sign-timestamp"] + data.headers["sign-appid"] + (method == "post" ? JSON.stringify(formData) : "");
        data.headers["sign-signature"] = this.get_sha256(str, data.SecretKey);
        axios({
          method: method,
          url: reqUrl + this.reqFnList[url].url,
          headers: {
            ...data.headers,
          },
          data:
            method == "post"
              ? {
                  ...formData,
                }
              : {},
          params:
            method == "get"
              ? {
                  ...formData,
                }
              : {},
        })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.code !== 200 && res.data.status !== 200) {
                that.$refs.optionPopupText.open(res.data.message);
              }
              that.isSubmit = false;
              resolve(res.data);
            }
          })
          .finally(() => {
            that.isLoading = false;
          });
      });
    },
    get_sha256(str, secret) {
      var signature = CryptoJS.HmacSHA256(str, secret).toString();
      signature = Base64.encode(signature);
      return signature;
    },
  },
};
