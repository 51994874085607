import store from '@/store'
import utils from '@/utils/utils.js'

const payment = {
  state: {
    Primitive:{}, // 原始数据
    gameList:[], // 渲染游戏列表
    gameIndex:0, // 选择游戏下标
    serverIndex:0 ,// 选择服务器下标

    productPrimitive:[], // 商品原始数据
    productPrimitiveList: [], // 渲染商品原始数据

    gameData:{}, // 游戏数据
    serverData:{}, // 服务器数据
  },

  mutations: {
    
    setPrimitive(state, data) { 
      state.Primitive = data;
    },
    setGameList(state, data) {
      state.gameList = data;
    },

    setproductPrimitive(state, data) {
      state.productPrimitive = data.productPrimitive;
      state.gameIndex = data.index;
      state.serverIndex = data.indexs;
    },

    setproductPrimitiveList(state, data) {
      state.productPrimitiveList = data;
    },
    setDatas(state,data){
      state.gameData = data.gameData;
      state.serverData = data.serverData;
    }
  },
  actions: {
    // 游戏数据  服务器数据处理
    dataHandle({state,commit}){
      let gameData = {
        game_name: '',
        icon:'',
        appid:state.Primitive[state.gameIndex].appid,
        appsecret: state.Primitive[state.gameIndex].appsecret,
        id:state.Primitive[state.gameIndex].id
      }
      let index = state.Primitive[state.gameIndex].params.findIndex((j) => j.language == store.state.init.lang);
      if(index > -1 ){
        gameData.game_name = state.Primitive[state.gameIndex].params[index].game_name
        gameData.icon = state.Primitive[state.gameIndex].params[index].icon
      }

      let serverData = {
        appid: state.Primitive[state.gameIndex].appid,
        id: state.Primitive[state.gameIndex].server_data[state.serverIndex].id,
        state:state.Primitive[state.gameIndex].server_data[state.serverIndex].state,
        area:'',
        banner:'',
        game_area_desc:'',
        game_area_name:'',
        page_title:'',
        recharge_desc:'',
        server_name:'',
        language:'',
        languageName:''
      };
      let serverIndex = state.Primitive[state.gameIndex].server_data[state.serverIndex].params.findIndex((j) => j.language == store.state.init.lang);
      if(serverIndex > -1 ){
        serverData = { ...serverData,...state.Primitive[state.gameIndex].server_data[state.serverIndex].params[serverIndex]}
      }
      commit('setDatas',{gameData,serverData})

    },
    // 商品数据处理
    productPrimitivehandle({state,commit,dispatch}){
      let data = {
        content:'',
        icon:'',
        language:'',
        languageName:'',
        product_name:'',
      }
      let list = []
      state.productPrimitive.forEach(i=>{
        let index = i.params.findIndex(j=>j.language == store.state.init.lang)
        let d = {...i}
        d.params = index > -1 ? i.params.find(j=>j.language == store.state.init.lang) : {...data}
        list.push(d)
      })
      let num = utils.IsPC() ? 5 : 2;
      let b = list.length % num === 0 ? parseInt(list.length / num) : parseInt(list.length / num) + 1;
      list = Array.from({ ...list, length: b * num }, (d = {}) => ({ ...d }));
      commit('setproductPrimitiveList',list)
    },
    // 游戏列表数据处理
    listrecombination({
      state,
      dispatch,
      commit
    }) {
      let list = [];
      state.Primitive.forEach((i) => {
        let index = i.params.findIndex((j) => j.language == store.state.init.lang);
        if (index > -1) {
          let listD = {
            id: i.id,
            appid: i.appid,
            appsecret: i.appsecret,
            show_name: i.show_name,
            params: i.params[index],
            server_data: [],
          };
          i.server_data.forEach((k) => {
            let serverIndex = k.params.findIndex((j) => j.language == store.state.init.lang);
            if (serverIndex > -1) {
              listD.server_data.push({
                appid: k.appid,
                id: k.id,
                state: k.state,
                params: k.params[serverIndex],
              });
            }
          });
          list.push(listD);
        }
        list.map((item, index) => {
          if (index % 2 === 0) {
            if (list[index + 1]) {
              let length = list[index].server_data.length > list[index + 1].server_data.length ? list[index].server_data.length : list[index + 1].server_data.length;
              for (let i = 0; i < length; i++) {
                list[index].server_data[i] = list[index].server_data[i] ? list[index].server_data[i] : {};
                list[index + 1].server_data[i] = list[index + 1].server_data[i] ? list[index + 1].server_data[i] : {};
              }
            }
          }
        });
        commit('setGameList',list) 
      });
    },
  }
}
export default payment
