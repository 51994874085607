import Vue from 'vue'
import Vuex from 'vuex'
import init from './modules/init'
import user from './modules/user'
import payment from './modules/payment'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
		createPersistedState({
			key: 'colocess_website', 
			paths: [
				'init.lang',
				'init.initData',
				'init.navList',
				'init.navIndex',
				'user.userInfo',
				'payment.Primitive',
				'payment.gameList',
				'payment.gameIndex',
				'payment.serverIndex',
				'payment.productPrimitive',
				'payment.productPrimitiveList',
				'payment.gameData',
				'payment.serverData',
			], 
			storage: { // 存储方式定义 
				getItem: (key) => localStorage.getItem(key), // 获取  
				setItem: (key, value) => localStorage.setItem(key, value), // 存储  
				removeItem: (key) => localStorage.removeItem(key) // 删除  
			}
		})
	],
  modules: {
		init,
    user,
		payment
  }
})

export default store
