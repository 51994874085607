import { mapState } from "vuex";
export default {
  data() {
    return {
      content:{},
      index:0,
      orderStatus: 0,
      queryData: {},
      payDate:''
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      lang: state => state.init.lang,
      gameData: state => state.payment.gameData,
    }),
  },
  activated() {
  },
  created() {},
  watch: {},
  mounted() {
    this.index = this.$route.query.index
    window.scrollTo(0, 0)
    this.queryData = { ...this.$route.query }
    this.loopOrderState()

  },
  methods: {
    loopOrderState() {
      this.T = setInterval(async () => {
        await this.$api.sdkGetOrderStatus({ orderId: this.queryData.orderID }).then(res => {
          if (res.code == 0) {
            this.orderStatus = res.data.state
            if(res.data.state == 1){
              this.payDate = this.getNowFormatDate()
              clearInterval(this.T)
            }
          } else {
            this.orderStatus = 2
            clearInterval(this.T)
          }
        })
      }, 1000)
    },
    getNowFormatDate() {
      let date = new Date(),
        obj = {
          year: date.getFullYear(), //获取完整的年份(4位)
          month: date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
          strDate: date.getDate(), // 获取当前日(1-31)
          week: '星期' + '日一二三四五六'.charAt(date.getDay()), //获取当前星期几(0 ~ 6,0代表星期天)
          hour: date.getHours(), //获取当前小时(0 ~ 23)
          minute: date.getMinutes(), //获取当前分钟(0 ~ 59)
          second: date.getSeconds() //获取当前秒数(0 ~ 59)
        }
     
      Object.keys(obj).forEach(key => {
        if (obj[key] < 10) obj[key] = `0${obj[key]}`
        // console.log(obj[key])
      })
     
      return `${obj.year}年${obj.month}月${obj.strDate}日${obj.week} ${obj.hour}:${obj.minute}:${obj.second}`
    }
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.T)
    
    next()
  },
};
