import api from '@/api'
const init = {
  namespaced: true,
  state: {
    initData:{},
    lang:'cn',
    navIndex: -1,
    
  },

  mutations: {
    setLanguage(state, data) {
      state.lang = data;
    },
    setInit(state,data){
      state.initData = data;
    }
  },
  actions: {
    getInits({
      dispatch,
      commit
    }){
      api.payConfigurationLogToList().then(res=>{
        if(res.code == 0){
          commit('setInit',res.data)
        }
      })
    },
    
    setNav({state},data){
      state.navIndex = data.index
    }
  }
}
export default init
