import { mapState } from "vuex";

export default {
  data() {
    return {
      Primitive: [],
      gameListM: [],
      isOptionPopup: false,
      TextShow: true,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.init.lang,
      initData: (state) => state.init.initData,
      gameList: (state) => state.payment.gameList,
    }),
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      this.$api.paymentGamesSelect().then((res) => {
        if (res.code == 0) {
          this.$store.commit("setPrimitive", res.data);
          this.$store.dispatch('listrecombination')
        }
      });
    },
    routes(item, items,index,indexs) {
      if(!items.state){
        return
      }
      if (items.state == 2) {
        this.$api.paymentProductsAll({ server_id: items.id }).then((res) => {
          if (res.code == 0) {
            res.data.map((item) => {
              item.price = item.price;
            });

            if (!res.data.length) {
              this.$refs.optionPopupText.open();
            } else {
              let d = {
                productPrimitive:res.data,
                index,
                indexs
              }
              this.$store.commit('setproductPrimitive',d)
              this.$store.dispatch('productPrimitivehandle')
              this.$store.dispatch('dataHandle')
              // return
              this.$router.push({ path: this.$utils.IsPC() ? "/PaymentDetalis" : "/PaymentDetalisM", query: {} });
              this.$bus.$emit("snavIndex", true);
            }
          }
        });
      } else {
        this.$refs.optionPopupText.open();
      }
    },
  },
};
