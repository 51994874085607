import axios from 'axios'
// import Adapter from 'axios-mock-adapter'
import { get } from 'lodash'
import { errorLog, errorCreate } from './tools'
import store from '@/store/index'
import { Message } from 'element-ui'
/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create()
  // 请求拦截
  service.interceptors.request.use(
    config => {
      let configData = {
        ...config,
      }
      let parameter = config.data
      if (parameter) {
        if (configData.method == 'get') {
          configData.params = parameter
        }
        if (configData.method == 'post') {
          if (configData.query) {
            configData.params = parameter
          } else {
            configData.data = parameter
          }

        }
      }
      configData.params.language = store.state.init.lang
      if (store.state.user.userInfo.loginToken) {
        config.headers.AuthorizationToken = 'Bearer ' + store.state.user.userInfo.loginToken
  
      }
      return configData
    },
    error => {
      // 发送失败
      console.log(error)
      return Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data
      // 这个状态码是和后端约定的
      const {
        code,
        message
      } = dataAxios
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
        return dataAxios
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 0:
            // [ 示例 ] code === 0 代表没有错误
            break
          case -1:
            Message.error(message)
            break
          case -3:
            Message.closeAll()
            store.commit("loginOut");
            Message.error('登录已过期，请重新登录')
            break
          default:
            // 不是正确的 code
            errorCreate(`${dataAxios.message}`)
            break
        }
        return dataAxios
      }
    },
    error => {
      const status = get(error, 'response.status')
      const data = get(error, 'response.data')
      console.log(status)
      switch (status) {
        case 400:
          error.message = '请求错误';
          break
        case 401:
          Message.closeAll()
          store.commit("loginOut");
          Message.error('登录已过期，请重新登录')
          break
        case 403:
          error.message = '拒绝访问';
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break
        case 408:
          error.message = '请求超时';
          break
        case 500:
          error.message = data.message;
          Message({
            type: "err",
            message: data.message,
          })
          break
        case 501:
          error.message = '服务未实现';
          break
        case 502:
          error.message = '网关错误';
          break
        case 503:
          error.message = '服务不可用';
          break
        case 504:
          error.message = '网关超时';
          break
        case 505:
          error.message = 'HTTP版本不受支持';
          break
        default:
          break
      }
      return Promise.reject(data)
    }
  )
  return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    // const token = util.cookies.get('token')
    const configDefault = {
      headers: {
        // 'Authorization': 'Bearer ' + (token || ''),
        'Content-Type': get(config, 'headers.Content-Type', 'application/json')
      },
      timeout: 5000,
      baseURL: process.env.VUE_APP_API,
    }
    return service(Object.assign(configDefault, config))
  }
}

// 用于真实网络请求的实例和请求方法
export const service = createService()
export const request = createRequestFunction(service)

// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService()
export const requestForMock = createRequestFunction(serviceForMock)

// 网络请求数据模拟工具
// export const mock = new Adapter(serviceForMock)
