
   const  messages = {
      Chinese: {
         optionPopupText: 'Coming Soon',
         bundle:'Select Bundle',
         instructions:'Top Up Details',
         products:'No products',
         banner:require('../../assets/imgs/banner-en.png'),
         nav: {
            home: 'Home',
            Payment: 'Payment',
            userAgreement: 'UserAgreement',
            privacyAgreement: 'PrivacyAgreement',
            about: 'ContactUs',
            Purchase: 'In-Game Purchase',
            language: "English"
         },
         main: {
            imgUrl: {
               shangzhai: require("../../assets/imgs/shangzhai-en.png"),
               haidao: require("../../assets/imgs/haidao.png")
            },
            title: {
               hd: 'Royal Pirates',
               sz: "Immortal Survivors"
            },
            content: {
               hd: "Auto Chess Idle Card Adventure Game",
               sz: "Fight to Survival!"
            },
            contentOne: {
               hd: `Royal Pirates is a mobile game with the theme of pirate adventure. The game takes a pirate as the perspective and records the people of different races and camps he met during his adventures and the wonderful stories that happened with them, showing a magnificent "Age of Discovery".`,
               sz: `100+ Skills! Only those who grasp the bigger picture can choose the right combination!`
            },
            contentTwo: {
               hd: "Game type: Strategy card",
               sz: "10,000+ Monsters! They come like tidal waves!"
            },
            contentThree: {
               hd: "Game features: massive cards, strategic battles, idle placement",
               sz: "100+ Equipment! Gamble for divine gear! Double the power of your skills!"
            },
            contentFour: {
               hd: ">>>>>Go explore",
               sz: ">>>>>Go explore"
            },
         },
         // 简介
         blurb: {
            title: {
               hd: "Passionate Unlimited Pirate Alliance",
               sz: "Global player peak competition"
            },
            content: {
               hd1: "Challenge a powerful alliance boss!",
               hd2: "Seize ownership of the sea area and dominate the sea area!",
               hd3: "Form the strongest pirate alliance with other captains and create a legendary feast!",
               sz1: "Join the arena and compete with players from all over the world!",
               sz2: "Launch a fierce attack on the leaderboard and the highest honor to win powerful spoils of war!"
            },
            imgUrl: {
               hd1: require("../../assets/imgs/hd1.jpg"),
               hd2: require("../../assets/imgs/hd2.jpg"),
               yj1: require("../../assets/imgs/yj1-en.jpg"),
               yj2: require("../../assets/imgs/yj2-en.jpg")
            }
         },
         // 用户协议
         user: {
            title: "Colocess Terms of Service",
            content: "1.Introduction",
            contentOne: `
                <p>1.1. Welcome to Colocess, if you use Colocess Applications versions other than the versions distributed in specific countries/regions includes Mainland China, Japan and Korea, as used in these terms and conditions of use (“Terms”), “Colocess”, “we”, “us”, or “our” refer to Colocess Technology Co., Ltd and its relevant affiliates for the distribution of Colocess Applications in the corresponding countries and/or regions.</p>
                <p>1.2. We provide our users (“Colocess Users”) with:</p>
                <ul>
                   <li><p>1.2.1 Applications for mobile, desktop platforms or devices, and web applications accessible on third-party social networking services (“SNS”) (collectively, our mobile, desktop and web applications are referred to herein as “Colocess Applications”)</p></li>
                   <li><p>1.2.2 Game publisher networks, crowd sourcing services and platforms (“Networks”) in connection with Colocess Applications and Third-Party Applications (defined below)</p></li>
                   <li><p>1.2.3 Web domains and subdomains, including Colocess.com (collectively, our “Sites”), and web forums or messaging boards in Colocess Applications and our Sites (collectively, our “Forums”)</p></li>
                   <li><p>1.2.4 Any and all of our Colocess Applications, Networks, Sites and Forums are referred to herein as our “Services”.</p></li>
                </ul>
                <p>1.3 Please read these Terms carefully because they govern your access to and use of our Services, Colocess Content, and User Content (defined below) and are legally binding.</p>
                <p>1.4 YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE “SIGN UP” BUTTON WHERE APPLICABLE, OR BY ACCESSING OR USING OUR SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT ON OR THROUGH OUR SERVICES, YOU ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED AS A MEMBER (DEFINED BELOW). IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE OUR SERVICES OR COLOCESS CONTENT. Certain Services, and your access to or use of certain Colocess Content, may have different, Service-specific or Colocess Content-specific terms and conditions (“Rules”) posted, or may require you to agree with and accept such additional Rules. If there is a conflict between these Terms and Service-specific Rules or Colocess Content-specific Rules, the applicable Rules will take precedence with respect to your use of, or access to, such Service or Colocess Content. In addition, Colocess offers access to Third-Party Mobile or Desktop Applications available via our Services (each, a ”Third-Party Application”), which are designed by third-party publishers (each, a ”Third-Party Publisher”). You understand and agree that access to, and use of, any Third-Party Application shall be subject to terms and conditions of use were provided by the Third-Party Publisher of such Third-Party Application (“Third-Party Terms”).</p>
                <h3>2. Modification</h3>
                <p>Colocess reserves the right, at its sole discretion, to modify, discontinue or terminate our Services, including any portion thereof, on a global or individual basis, or to modify these Terms, at any time and without prior notice. If we modify these Terms, we will post these Terms as modified or otherwise provide you with notice of the modification. By continuing to access or use our Services after we have posted a modification to these Terms or have provided you with notice of a modification, you are indicating that you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using our Services.</p>
                <h3>3. No Unlawful or Prohibited Use</h3>
                <p>By using our Services, you represent and warrant to Colocess that you will not use our Services, or any of the content obtained from our Services, for any purpose that is unlawful or prohibited by these Terms.</p>
                <h3>4. Eligibility and Registration</h3>
                <p>4.1 In order to access the full feature set of our Services, and to post any User Content on or through our Services, you must register to create a Colocess account (“Colocess Account”) (any Colocess Account, collectively, “Colocess Account”) and become a “Member.”</p>
                <p>4.2 YOU AGREE THAT IF YOU ARE UNDER THE AGE OF 18 OR WHATEVER IS THE AGE OF LEGAL MAJORITY WHERE YOU ACCESS THE SERVICES, YOU REPRESENT THAT YOUR LEGAL GUARDIAN HAS READ, UNDERSTOOD, AND AGREED TO THESE TERMS.</p>
                <p>4.3 In registering for a Colocess Account, you agree to monitor the account to restrict any use by minors. You accept full responsibility for any unauthorized use of our Services by minors, and this includes any use of your credit cards or other payment or settlement instruments or devices by minors. During the registration process, you will be required to provide certain information, including your email address, and you will establish a username and a password. You agree to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete. Colocess reserves the right to suspend or terminate your Colocess Account if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete.</p>
                <p>4.4 If you are not a Member, you may only browse or use in limited ways those parts of our Services that are not limited to Members only. You are responsible for safeguarding your password. You agree not to disclose your password to any third party or to allow any third party to use your Colocess Account, and you agree to take sole responsibility for any activities or actions under your Colocess Account, whether or not you have authorized such activities or actions. You will immediately notify Colocess of any unauthorized use of your Colocess Account.</p>
                <p>4.5 EVEN THOUGH WE USE THE WORD “YOUR” TO DESCRIBE A COLOCESS ACCOUNT YOU REGISTERED FOR, YOU AGREE THAT YOU HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN THE COLOCESS ACCOUNT AT ALL, AND YOU FURTHER AGREE THAT ALL RIGHTS IN AND TO THE COLOCESS ACCOUNT ARE AND ALWAYS WILL BE OWNED BY AND INURE TO THE BENEFIT OF COLOCESS.</p>
                <h3>5. Privacy</h3>
                <p>Use of our Services is also governed by Colocess's Privacy Policy. If you have any questions about the Colocess Privacy Policy, please contact us via email at: kf@colocess.com</p>
                <h3>6. Content</h3>
                <p>6.1 Certain types of content are made available on or through our Services:</p>
                <ul>
                   <li><p>6.1.1 User Content means text, data, graphics, images, photos, video or audiovisual content, hypertext links and any other content uploaded, transmitted or submitted by a Member on or through our Services.</p></li>
                   <li><p>6.1.2 Colocess Content means text, data, graphics, images, illustrations, forms, documents, marketing materials, look and feel attributes, Colocess or our licensors' trademarks and logos, Colocess Applications and Third-Party Applications, and other content made available by Colocess on or through our Services, including any Customizations but excluding User Content.</p></li>
                   <li><p>6.1.3 Customizations means the modifications, designs or enhancements to Colocess Content as a result of a Member's or a non-registered user's customization of a Colocess character using one of Colocess's interactive templates or design tools. For the avoidance of doubt, Customizations do not include User Content.</p></li>
                </ul>
                <h3>7. Ownership</h3>
                <p>7.1 Our Services and Colocess Content are protected by copyright, trademark, and other laws of People's Republic of China and foreign countries.</p>
                <p>7.2 Except as expressly provided in these Terms, Colocess and our licensors exclusively own all right, title and interest in and to the Services and Colocess Content, including all associated intellectual property rights, including any patents, copyrights, trademarks, service marks, trade names, database rights, domain name rights, applications for any of the foregoing, moral rights and trade secret rights (“Intellectual Property Rights”).</p>
                <p>7.3 Colocess will exclusively own all right, title and interest in and to any Customizations and you hereby waive any and all rights you may have in any Customizations under copyright law or otherwise. You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying our Services or Colocess Content.</p>
                <h3>8. Colocess Content License</h3>
                <p>Subject to your compliance with these Terms, Colocess hereby grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access, view, download and print, where applicable, any Colocess Content solely for your personal and non-commercial purposes. You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services or Colocess Content, except only as expressly permitted by these Terms. No licenses or rights are granted to you by implication or otherwise under any Intellectual Property Rights owned or controlled by Colocess or its licensors, except for the licenses and rights expressly granted by these Terms.</p>
                <h3>9. User Content</h3>
                <p>9.1 By making available any User Content on or through our Services, you hereby grant to Colocess a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, use, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast and otherwise exploit such User Content only on, through or by means of our Services. Colocess does not claim any ownership rights in any such User Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit any such User Content.</p>
                <p>9.2 You acknowledge and agree that you are solely responsible for all User Content that you make available on or through our Services. Accordingly, you represent and warrant that: you either are the sole and exclusive owner of all User Content that you make available on or through our Services or you have all rights, licenses, consents and releases that are necessary to grant to Colocess the rights in such User Content as contemplated under these Terms; and neither the User Content nor your posting, uploading, publication, submission or transmittal of the User Content or Colocess's use of the User Content (or any portion thereof) on, through or by means of our Services will infringe, misappropriate or violate a third party's Intellectual Property Rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</p>
                <h3>10. No Infringing Use.</h3>
                <p>You will not use our Services to offer, display, distribute, transmit, route, provide connections to or store any material that infringes copyrighted works or otherwise infringes, violates, or promotes the infringement or violation of the Intellectual Property Rights of any third party.</p>
                <h3>11. Ratings, Chat, Comments & Feedback</h3>
                <p>11.1 You can chat and make comments about content made available on or through our Services (“Comments”). Colocess advises you to exercise caution and good judgment when leaving such Comments. We do not generally monitor, review or moderate your Comments. Once you complete and submit your Comments on or through our Services, you should not expect to be able to go back and edit your Comments, nor should you expect us to make modifications to, or deletions of, your Comments on your behalf.</p>
                <p>11.2 You should be aware that you could be held legally responsible for damages to someone's reputation if your Comments are deemed to be defamatory. Without limiting any other terms or conditions of these Terms, Colocess may, but is under no obligation to do so, monitor or censor Comments and disclaims any and all liability relating thereto. Notwithstanding the foregoing, Colocess does reserve the right, in its sole discretion, to remove any Comments that it deems to be improper, inappropriate or inconsistent with the online activities that are permitted under these Terms.</p>
                <p>11.3 We welcome and encourage you to provide feedback, comments and suggestions for improvements to our Services (“Feedback”). You may submit Feedback by emailing us at Sunzstudio0305@gmail.com or by submitting through our Feedback system within our Services.</p>
                <p>11.4 You acknowledge and agree that all Feedback will be the sole and exclusive property of Colocess and you hereby irrevocably assign to Colocess and agree to irrevocably assign to Colocess all of your right, title, and interest in and to all Feedback, including without limitation all worldwide Intellectual Property Rights therein. Further, we are under no obligation to provide compensation for any Feedback. At Colocess's request and expense, you will execute documents and take such further acts as Colocess may reasonably request to assist Colocess to acquire, perfect, and maintain its Intellectual Property Rights and other legal protections in the Feedback.</p>
                <h3>12. Virtual Items and Virtual Currency</h3>
                <p>12.1 Colocess owns, has licensed, or otherwise has the rights to use all Colocess Content appearing or originating in the Services, Colocess Applications or Third-Party Applications, including virtual items (“Virtual Items”) and virtual currency (“Virtual Currency”). Virtual Items and Virtual Currency are provided solely for your personal and entertainment use, they may only be used in Colocess Applications or Third-Party Applications, and they have no “real world” value. By purchasing or receiving Virtual Items and Virtual Currency all that you receive is a limited license to use them in Colocess Applications or Third-Party Applications by these Terms or such other terms as may apply; Virtual Items and Virtual Currency are not your personal property and no ownership interest in them is transferred to you. The prices for and the amounts and kinds of Virtual Items and Virtual Currency available may be changed at any time without notice. Virtual Items and Virtual Currency that you receive may also be changed or discontinued at any time without notice.</p>
                <p>12.2 You cannot sell or transfer, or attempt to sell or transfer, Virtual Items or Virtual Currency, except only that where allowed you can exchange, within the Services, Colocess Applications, and Third-Party Applications, those Virtual Items and Virtual Currency that cannot be purchased with “real world” money (“Tradable Items”) for other Tradable Items so long as no money or anything of monetary value is paid or given for Tradable Items; any other purported or attempted exchange is strictly prohibited. Virtual Items and Virtual Currency may never be redeemed by you for “real world” money, goods, wares, merchandise, services, or anything of monetary value from Colocess or any other person.</p>
                <h3>13. Orders & Payments</h3>
                <p>13.1 YOU AGREE THAT IF YOU ARE UNDER THE AGE OF 18 OR WHATEVER IS THE AGE OF LEGAL MAJORITY WHERE YOU ACCESS THE SERVICES, YOU MAY MAKE PAYMENTS ONLY WITH THE INVOLVEMENT OF YOUR LEGAL GUARDIAN, AND YOU REPRESENT THAT YOUR LEGAL GUARDIAN HAS READ, UNDERSTOOD, AND AGREED TO THESE TERMS.</p>
                <p>13.2 You may purchase, with “real world” money, limited licenses to use Virtual Items or Virtual Currency from Colocess in accordance with these Terms, and you agree that all such purchases are final. If you order licenses for Virtual Items or Virtual Currency from Colocess that become unavailable before they can be provided to you, your only remedy is to request a refund of the purchase price from the payment processor of the transaction. Your orders for limited licenses to Virtual Items or Virtual Currency are offers for use of those Virtual Items or Virtual Currency, and if accepted those Virtual Items or Virtual Currency will be immediately downloaded to your Colocess Account. You expressly consent to the making available of Virtual Items and Virtual Currency immediately upon acceptance of your order.</p>
                <p>13.3 If you are a resident of the European Union and you purchase limited licenses to Virtual Items and Virtual Currency from us, the right to withdraw from such purchases within 7 working days of the date of purchase (“Cooling Off Period”) may be available to you; however, this right of withdrawal will not apply where our performance begins before the end of the Cooling Off Period. Therefore, you expressly agree and understand that if you order limited licenses to Virtual Items or Virtual Currency from us, your right of withdrawal is forfeited upon acceptance as performance begins immediately on acceptance. You understand and agree that Colocess provides no refunds for any purchases except only as expressly stated in these Terms.</p>
                <h3>14. Taxes</h3>
                <p>You are responsible for and will pay all fees and applicable taxes incurred by you or anyone using a Colocess Account registered to you.</p>
                <h3>15. Mobile, Desktop Operating Software Providers and Third-Party Publishers</h3>
                <p>15.1 Providers of operating software for mobile, desktop devices (“OS Providers”) offer virtual storefronts and marketplaces for you to browse, locate and download, among other things, mobile or desktop applications. If you download Colocess Applications or Third-Party Applications from a virtual storefront or marketplace operated by your OS Provider, please note that, in addition to complying with these Terms (and the terms and conditions of any applicable Third-Party Publisher), you must also comply with the terms and conditions of such virtual storefront or marketplace, such, as for example, Google’s Android Market.</p>
                <p>15.2 As for Third-Party Publishers, although Colocess is not a party to any license between you and any Third-Party Publishers with respect to Third-Party Applications, you acknowledge and agree that Colocess is a third-party beneficiary of each such Third-Party Application's end user license agreement. You also agree that, upon your acceptance of the terms and conditions of the license to any such Third-Party Application, Colocess, as a third-party beneficiary, will have the right (and will be deemed to have accepted the right) to enforce such license against you.</p>
                <h3>16. Interactions between Users</h3>
                <p>16.1 You are solely responsible for your interactions (including any disputes) with other Colocess Users. Even if we choose to offer report user, block user, or similar features on our Services, you will remain solely responsible for, and you must exercise caution, discretion, common sense and judgment in, using our Services and disclosing personal information to other Colocess Users. You agree to take reasonable precautions in all interactions with other Colocess Users, particularly if you decide to meet a Colocess User offline, or in person. Your use of our Services, Colocess Content, User Content and any other content made available through our Services is at your sole risk and discretion and Colocess hereby disclaims any and all liability to you or any third party relating thereto.</p>
                <p>16.2 Colocess reserves the right to contact Members, in compliance with applicable law, in order to evaluate compliance with these Terms and any other applicable Rules. You will cooperate fully with Colocess to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting authorized Colocess representatives’ access to any password-protected portions of your Colocess Account.</p>
                <h3>17. General Prohibitions</h3>
                <p>17.1 You agree not to do any of the following while using our Services, Colocess Content, or User Content:</p>
                <ul>
                   <li><p>17.1.1 Post, upload, publish, submit or transmit any text, graphics, images, software, music, audio, video, information or other material that: infringes, misappropriates or violates a third party's Intellectual Property Rights, or rights of publicity or privacy;</p></li>
                   <li><p>17.1.2 violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; is fraudulent, false, misleading or deceptive; is defamatory, obscene, pornographic, vulgar or offensive; promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; is violent or threatening or promotes violence or actions that are threatening to any other person; or promotes illegal or harmful activities or substances (including but not limited to activities that promote or provide instructional information regarding the manufacture or purchase of illegal weapons or illegal substances).</p></li>
                   <li><p>17.1.3 Use, display, mirror, frame or utilize framing techniques to enclose our Services, or any individual element or materials within our Services, Colocess's name, any Colocess or Colocess licensor's trademark, logo or other proprietary information, the content of any text or the layout and design of any page or form contained on a page, without Colocess's express written consent;</p></li>
                   <li><p>17.1.4 Access, tamper with, or use non-public areas of our Services, Colocess's computer systems, or the technical delivery systems of Colocess's providers;</p></li>
                   <li><p>17.1.5 Attempt to probe, scan, or test the vulnerability of any Colocess system or network or breach any security or authentication measures;</p></li>
                   <li><p>17.1.6 Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Colocess or any of Colocess's providers or any other third party (including another Colocess User) to protect our Services or Colocess Content;</p></li>
                   <li><p>17.1.7 Attempt to access or search our Services or Colocess Content, or download Colocess Content from our Services, through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Colocess or other generally available third-party web browsers (such as Google Chrome, Microsoft Internet Explorer, Mozilla Firefox, Apple Safari or Opera);</p></li>
                   <li><p>17.1.8 Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;</p></li>
                   <li><p>17.1.9 Use any meta tags or other hidden text or metadata utilizing a Colocess or Colocess licensor's trademark, logo URL or product name without Colocess's express written consent;</p></li>
                   <li><p>17.1.10 Use our Services or Colocess Content for any commercial purpose or the benefit of any third party or in any manner not permitted by these Terms;</p></li>
                   <li><p>17.1.11 Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way our Services or Colocess Content to send altered, deceptive or false source-identifying information;</p></li>
                   <li><p>17.1.12 Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide our Services or Colocess Content; Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing our Services;</p></li>
                   <li><p>17.1.13 Collect or store any personally identifiable information from our Services, or from other users of our Services, without their express permission; Impersonate or misrepresent your affiliation with any person or entity;</p></li>
                   <li><p>17.1.14 Violate any applicable law or regulation; Encourage or enable any other individual to do any of the foregoing;</p></li>
                   <li><p>17.1.15 Post User content or take any action that infringes or violates the rights of another Member: Bully, harass or intimidate any Member of the Services; Solicit Member login credentials from another Member or collect User content or otherwise access the Services by automated means including but not limited to, bots, robots, spiders;</p></li>
                   <li><p>17.1.16 Create a Colocess Account for anyone other than yourself;</p></li>
                   <li><p>17.1.17 Use your Member profile for personal commercial gain;</p></li>
                   <li><p>17.1.18 Use cheats, exploits, hacks, bots, mods or third-party software designed to gain an advantage, perceived or actual, over other Members, or modify or interfere with the Service;</p></li>
                   <li><p>17.1.19 Abuse or exploit a bug, glitch or mechanism in the Service; or Engage in any fraudulent behavior, including but not limited to credit card scams or credit card misappropriation.</p></li>
                   <li><p>17.1.20 Posting spam or reposting removed content in the official Colocess Forums. This includes but is not limited to posting the same content multiple times or reposting a thread or topic that has been removed from the forums.</p></li>
                   <li><p>17.1.21 Unsportsmanlike behavior. Account sharing, including but not limited to the sharing of username and password for others to login for you.</p></li>
                </ul>
                <p>17.2 Colocess will have the right to investigate and prosecute violations of any of the above, including Intellectual Property Rights infringement and Services security issues, to the fullest extent of the law. Colocess may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.</p>
                <p>17.3 You acknowledge that Colocess has no obligation to monitor or record your access to or use of our Services or Colocess Content, or to monitor, record, or edit any User Content, but agree that we have the right to do so for the purpose of operating our Services, to ensure your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. You acknowledge and agree that you have no expectation of privacy concerning uploads, transmissions, or submissions of any User Content.</p>
                <p>17.4 Colocess reserves the right, at any time and without prior notice, to remove or disable access to any User Content that Colocess, in its sole discretion, considers to be in violation of these Terms or otherwise harmful to our Services. We encourage Colocess Users to report any suspected misconduct or misuse of our Services by sending us an email to kf@colocess.com</p>
                <h3>18. Links and Third-Party Materials Links and Third-Party Materials Links and Third-Party Materials</h3>
                <p>18.1 Our Services may contain links to third-party websites or resources. You acknowledge and agree that Colocess is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Colocess of such websites or resources or the content, products, or services available from such websites or resources.</p>
                <p>18.2 You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources. If you elect to use our Services on mobile, desktop carrier networks, such carrier's normal billing rates will apply. In addition, certain content, products, and services available via our Services may include materials from third parties, including Third-Party Applications.</p>
                <p>18.3 You agree that Colocess is not responsible for examining or evaluating third-party content, or such content's accuracy, and Colocess does not warrant and will not have any liability or responsibility for any Third-Party Applications, or for any other materials, products, or services of third parties.</p>
                <p>18.4 You agree that you will not use any Third-Party Applications, or other third-party materials, in a manner that would infringe or violate the rights of any other party, and that Colocess is not in any way responsible for any such use by you.</p>
                <h3>19. Sweepstakes and Contests</h3>
                <p>Colocess may permit the offer of sweepstakes, contests and similar promotions (collectively, “Promotions”) through the Services. You should carefully review the rules (e.g., the “Official Rules”) of each Promotion in which you participate through the Services, as they may contain additional important information about Colocess's rights to and ownership of the submissions you make as part of the Promotions and as a result of your participation in such Promotion. To the extent that the terms and conditions of such Official Rules conflict with these Terms, the terms and conditions of such Official Rules will control.</p>
                <h3>20. Termination of Colocess Accounts</h3>
                <p>20.1 Without limiting other remedies, Colocess may at any time suspend or terminate your Colocess Account and refuse to provide access to our Services if Colocess suspects or determines, in its own discretion, that you may have or there is a significant risk that you have: (i) failed to comply with any provision of these Terms or any policies or Rules established by Colocess; (ii) engaged in actions relating to or in the course of using our Services that may be illegal or cause liability, harm, embarrassment, harassment, abuse or disruption for you, Colocess Users, Colocess or any other third parties or our Services; or (iii) infringed the proprietary rights, rights of privacy, or Intellectual Property Rights of any person, including as a repeat infringer.</p>
                <p>20.2 In addition, Colocess may notify authorities or take any other actions it deems appropriate, without notice to you, in the event of any of the above. You may terminate your Colocess Account at any time and for any reason by sending an email to kf@colocess.com</p>
                <h3>21. Effects of Termination, Suspension of Account</h3>
                <p>21.1 Upon termination of your Colocess Account for any reason by you or us, you will lose all access to such account. Terminated Colocess Accounts cannot be reinstated; any Colocess Account that may be registered by you after termination of a Colocess Account is a unique account.</p>
                <p>21.2 If your Colocess Account is terminated for any reason by you or us, you understand and agree that any Virtual Items to which you had access via your Colocess Account at the time of termination will be lost and no longer be available to you, and you will have no right to them. If your Colocess Account is terminated for any reason by you or us, you understand and agree that Colocess may redeem and use the Virtual Currency in the Colocess Account at the time of termination for any purpose that it may choose, and that on termination you will have no right to that Virtual Currency. In addition, if there is no activity in your Colocess Account for 3 years (Inactive Account), you understand and agree that (i) Colocess may redeem and use the Virtual Currency in the Inactive Account at such time for any purpose that it may choose, and that on the account becoming an Inactive Account you will have no right to that Virtual Currency; and (ii) any Virtual Items to which you had access via the Inactive Account up to the account becoming an Inactive Account will be lost and no longer be available to you, and you will have no right to them.</p>
                <p>21.3 YOU AGREE THAT COLOCESS IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS OR VIRTUAL CURRENCY IN AN INACTIVE ACCOUNT OR THAT WAS IN A TERMINATED COLOCESS ACCOUNT, NO MATTER HOW EITHER CAME ABOUT. After any termination, you understand and acknowledge that we will have no further obligation to provide our Services and all licenses and other rights granted to you by these Terms will immediately cease. Colocess will not be liable to you or any third party for termination of our Services or termination of your use of our Services.</p>
                <p>21.4 UPON ANY TERMINATION OR SUSPENSION OF YOUR COLOCESS ACCOUNT, ANY CONTENT, MATERIALS OR INFORMATION (INCLUDING USER CONTENT) THAT YOU HAVE SUBMITTED ON OUR SERVICES OR THAT WHICH IS RELATED TO YOUR COLOCESS ACCOUNT MAY NO LONGER BE ACCESSED BY YOU. Furthermore, Colocess will have no obligation to maintain any information stored in our database related to your Colocess Account or to forward any information to you or any third party. Any suspension, termination or cancellation will not affect your obligations to Colocess under these Terms (including, without limitation, proprietary rights and ownership, indemnification and limitation of liability), which by their sense and context are intended to survive such suspension, termination or cancellation.</p>
                <h3>22. Disclaimers</h3>
                <p>OUR SERVICES, COLOCESS CONTENT, AND USER CONTENT ARE PROVIDED AS IS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, COLOCESS EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. COLOCESS MAKES NO WARRANTY THAT OUR SERVICES, COLOCESS CONTENT OR USER CONTENT WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. COLOCESS MAKES NO WARRANTY REGARDING THE QUALITY OF ANY PRODUCTS, SERVICES OR CONTENT PURCHASED OR OBTAINED THROUGH OUR SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT OBTAINED THROUGH OUR SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM COLOCESS OR THROUGH OUR SERVICES, COLOCESS CONTENT OR USER CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</p>
                <h3>23. Indemnity</h3>
                <p>You agree to defend, indemnify, and hold Colocess, its officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with User Content you submit to Colocess, your access to our Services or Colocess Content, or your violation of these Terms.</p>
                <h3>24. Limitation of Liability</h3>
                <p>YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF OUR SERVICES, COLOCESS CONTENT, AND USER CONTENT THEREIN REMAINS WITH YOU. NEITHER COLOCESS NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING OUR SERVICES OR COLOCESS CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE OUR SERVICES OR CONTENT THEREIN, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT COLOCESS HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. YOU SPECIFICALLY ACKNOWLEDGE THAT COLOCESS IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU. FURTHER, COLOCESS WILL HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY USER CONTENT OR THIRD-PARTY CONTENT UPLOADED ONTO OR DOWNLOADED FROM OR THROUGH OUR SERVICES. IN NO EVENT WILL COLOCESS'S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE OUR SERVICES OR COLOCESS CONTENT OR USER CONTENT THEREIN OR PROVIDED THEREBY EXCEED ONE HUNDRED U.S. DOLLARS ($100). THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN COLOCESS AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY OF CONSEQUENTIAL OR INCIDENTAL DAMAGES, AND SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
                <h3>25. Trademarks & Other Proprietary Rights Notices</h3>
                <p>All trademarks, service marks, logos, trade names and any other proprietary designations of Colocess or our licensors used in association with the Services are trademarks or registered trademarks of Colocess or our licensors. Any other trademarks, service marks, logos, trade names and any other proprietary designations are the trademarks or registered trademarks of the respective owners of same.</p>
                <h3>26. Controlling Law and Jurisdiction</h3>
                <p>These Terms and any action related thereto will be governed by the laws of People’s Republic of China without regard to its conflict of law’s provisions. Any dispute arising from or in connection with These Terms shall be submitted to Shanghai International Economic and Trade Arbitration Commission/Shanghai International Arbitration Center (“SHIAC”) for arbitration, which shall be conducted in accordance with SHIAC's arbitration rules in effect at the time of applying for arbitration. The seat of arbitration shall be Shanghai, China. The language to be used in the arbitral proceedings shall be Chinese. The arbitral award is final and binding upon both parties.</p>
                <h3>27. Entire Agreement</h3>
                <p>These Terms constitute the entire and exclusive understanding and agreement between Colocess and you regarding our Services and Colocess Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between Colocess and you regarding our Services and Colocess Content.</p>
                <h3>28. Assignment</h3>
                <p>You may not assign or transfer these Terms, by operation of law or otherwise, without Colocess's prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and of no effect. Colocess may freely assign these Terms. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</p>
                <h3>29. Notices</h3>
                <p>29.1 You consent to the use of: (i) electronic means to complete these Terms and to deliver any notices or other communications permitted or required hereunder; and (ii) electronic records to store information related to these Terms or your use of our Services.</p>
                <p>29.2 Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms, will be in writing and given: (i) by Colocess via email (in each case to the address that you provide) or (ii) by posting on or through our Services. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.</p>
                <h3>30. General</h3>
                <p>30.1 The failure of Colocess to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Colocess.</p>
                <p>30.2 Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible, and the other provisions of these Terms will remain in full force and effect.</p>
                <p>30.3 You agree that (i) these Terms are intended to cover any Third-Party Publisher who is an affiliate of Colocess Technology Co., Ltd (“Affiliate Publisher”); (ii) your obligations to Colocess under these Terms extend to Affiliate Publishers as applicable unless otherwise agreed under any Third-Party Terms with any such Affiliate Publisher; and (iii) Affiliate Publishers are third party beneficiaries under these Terms who may rely on and directly enforce these Terms against you as such.</p>
                <h3>31. Contacting Us</h3>
                <p>If you have any questions about these Terms, please contact Colocess at kf@colocess.com</p>
                `,
            footer: `
                
                `
         },
         // 隐私协议
         duty: {

            title: "Colocess Privacy Policy",
            content: "INTRODUCTION",
            footer: "",
            contentOne: `
                   <p>If you use Colocess Applications versions other than the versions distributed in specific countries/regions includes Mainland China, Japan and Korea, you acknowledge that you have read and understood this privacy policy. If you do not agree with this privacy policy (“this policy”), you must not use Colocess Services. If you change your mind in the future, you may withdraw your consent to use of your personal information in accordance with this policy. YOU AGREE THAT IF YOU ARE UNDER THE AGE OF 18 OR WHATEVER IS THE AGE OF LEGAL MAJORITY WHERE YOU ACCESS THE SERVICES, YOU REPRESENT THAT YOUR LEGAL GUARDIAN HAS READ, UNDERSTOOD, AND AGREED TO THIS POLICY.</p>
                   <p>At Colocess, we know your privacy is very important to you, so please take the time to carefully read our privacy policy. Our policy is designed to help you understand</p>
                   <p>1、What information do we collect about you;</p>
                   <p>2、How will we use the information about you;</p>
                   <p>3、Who do we share your information with;</p>
                   <p>4、How long do we keep hold of your information;</p>
                   <p>5、How can I exercise my rights over my information;</p>
                   <p>6、Dispute Resolution;</p>
                   <p>7、How will we notify you of changes;</p>
                   <p>8、Contact Information;</p>
                   <p>This privacy policy is an inseparable part of Colocess Terms of Service provided by Colocess  Colocess Technology Co., Ltd. This policy applies to all Colocess services that directly reference or link to this policy but does not apply to Colocess services that have separate privacy policies that do not incorporate this policy.</p>
                   <h3>SECTION 1： WHERE WE ARE A DATA CONTROLLER</h3>
                   <p>1.The Types of Personal Information We Use</p>
                   <p>This section describes the different types of personal information we collect and how we collect it. We will collect and use the following information about you:</p>
                   <p>1.1. Information you provide us.</p>
                   <ul>
                    <li><p>a) when you register for the Game (including your nominated nickname, or custom accounts and information we import from your connected social media account (WeChat, Facebook, Google) in order to set up your profile, including your name as it appears on your social media profile and your profile picture);</p></li>
                    <li><p>b) when you log-in as a ‘guest’ in order to use the Game (your nickname);</p></li>
                    <li><p>c) when you participate in a survey in the Game (in which case we will process the information you provide to us as part of the survey)</p></li>
                    <li><p>d) when you transact through our Services (if applicable) or with our third-party payment service providers authorized by us.</p></li>
                    <li><p>e) when you link your Account with Your Social Media Accounts (WeChat, Facebook, Google, etc.) or other external accounts or use other social media functions in accordance with Provider's policies.</p></li>
                    <li><p>f) when you use our Services or interact with other users and/or us through our Services. This includes, but is not limited to, the cookies that may be generated when you interact with us.</p></li>
                   </ul>
                   <p>1.2. Information we collect about you.</p>
                   <ul>
                     <li><p>a) Name;</p></li>
                     <li><p>b) Generated username and open id;</li>
                     <li><p>c) Address, email address, telephone number (“Contact Information”);</p></li>
                     <li><p>d) Transaction information;</p></li>
                     <li><p>e) Age, gender, date of birth;</p></li>
                     <li><p>f) Photograph;</p></li>
                     <li><p>g) Usage Data and transaction data;</p></li>
                     <li><p>h) Location related information, including geographical location and IP address;</p></li>
                     <li><p>i) Survey content and responses;</p></li>
                     <li><p>j) Advertising ID;</p></li>
                     <li><p>k) Customer Support Ticket ID & User Communications with Support;</p></li>
                     <li><p>l) Game Play Statistics (Level, Score/s);</p></li>
                     <li><p>m) Device Information(such as application version, network type, OS);</p></li>
                     <li><p>n) Marketing and communication data, such as your preferences for receiving marketing materials, your communication preferences, and your communication history with us and our service providers.</p></li>
                     <li><p>o) Chat data; and</p></li>
                     <li><p>p) Security-related information.</p></li>
                   </ul>
                   <p>1.3. Data we collect from our partners.</p>
                   <ul>
                    <li><p>a) Data we receive if you link a third-party tool with the Service (such as WeChat, Facebook, Google). If you register as a user of our Services using your social media account (e.g., WeChat, Facebook, Google, etc.) and link your account to your social media account, we may access information about you that you voluntarily provide to your social media account provider in accordance with that provider's policies, and we will manage and use such personal data in accordance with this Policy.</p></li>
                    <li><p>b) Demographic data (such as to determine the coarse location of your Ip address)</p></li>
                    <li><p>c) Data to fight fraud (such as refund abuse in games or click fraud in advertising)</p></li>
                    <li><p>d) Data from platforms that the games run on (such as to verify payment)</p></li>
                    <li><p>e) Data for advertising and analytics purposes, so we can provide you a better Service</p></li>
                   </ul>
                   <p>1.4. Cookies.</p>
                   <p>We use cookies and other similar technologies (e.g., web beacons, log files, scripts and eTags) (“Cookies”) to enhance your experience using the Colocess Services. Cookies are small files which, when placed on your device, enable us to provide certain features and functionality. You have the option to permit installation of such Cookies or subsequently disable them. You may accept all cookies, or instruct the device (in the case of the Game) to provide notice at the time of installation of cookies, or refuse to accept all cookies by adjusting the relevant cookie retention function in your device. However, in the event of your refusal to install cookies, the Game may be unable to operate as designed.</p>
                   <p>2. How we use your personal information</p>
                   <p>2.1 We will use the information in the following ways:</p>
                   <ul>
                     <li><p>2.1.1 to create your account for the Game in accordance with your request;</p></li>
                     <li><p>2.1.2 Generated username and open id: to store your Game data (including level and progress) with your profile;</p></li>
                     <li><p>2.1.3 To contact or communicate with you by telephone, SMS and/or fax messages, email and/or postal mail or other means to manage and/or administer your relationship with us or your use of our services;</p></li>
                     <li><p>2.1.4 to maintain a record of your transaction history;</p></li>
                     <li><p>2.1.5 to manage, operate, provide and/or administer your use of and/or access to our Services (including, without limitation, your preferences), as well as your relationship and Account with us;</p></li>
                     <li><p>2.1.6 for identification and/or verification; (where applicable)</p></li>
                     <li><p>2.1.7 to conduct research, analysis and development activities (including, but not limited to, data analytics, surveys, product and service development and/or profiling), to analyze how you use our Services, to improve our Services or products and/or to enhance your customer experience;</p></li>
                     <li><p>2.1.8 If you enable chat services (audio or text) then we will process such data in order to deliver your messages to other users;</p></li>
                     <li><p>2.1.9 for security and verification purposes; identify and address bugs and assess game function for optimize action; solve game crashes and optimize compatibility of devices with the game; and to combat users registering for multiple accounts;</p></li>
                     <li><p>2.1.10 to allow other users to interact, communicate, or connect with you, including to inform you when another user has sent you a private message or posted a comment for you via the Game or Services;</p></li>
                     <li><p>2.1.11 to store, host, back up (whether for disaster recovery or otherwise) of your personal data, whether within or outside of your jurisdiction;</p></li>
                     <li><p>2.1.12 to carry out due diligence or other screening activities (including, without limitation, background checks) in accordance with legal or regulatory obligations or our risk management procedures that may be required by law or that may have been put in place by us;</p></li>
                     <li><p>2.1.13 to respond to legal processes or to comply with or as required by any applicable law, governmental or regulatory requirements of any relevant jurisdiction, including, without limitation, meeting the requirements to make disclosure under the requirements of any law binding on us or related corporations or affiliates;</p></li>
                     <li><p>2.1.14 for marketing and, advertising for any marketing activities where permissible under applicable law, and in this regard, to recommend products and/or services relevant to your interests, to send you by various mediums and modes (including email or SMS) of communication marketing and promotional information and materials relating to products and/or services (including, without limitation, products and/or services of third parties whom We may collaborate or tie up with) that We (and/or its affiliates or related corporations) may be selling, marketing or promoting, whether such products or services exist now or are created in the future. You can unsubscribe from receiving marketing information at any time by using the unsubscribe function within the electronic marketing material. We may use your contact information to send newsletters or marketing materials from us and from our related companies;</p></li>
                     <li><p>2.1.15 other purposes which are permissible under applicable law and which we notify you of at the time of obtaining your consent.</p></li>
                   </ul>
                   <p>Such purposes may not be the case because the purposes for which we will collect/may collect, use, disclose or process your personal data depend on the circumstances at hand. However, unless Privacy Laws permit processing of applicable data without your consent, we will notify you of other purposes at the time we obtain your consent.</p>
                   <p>2.2 analyze, profile, and segment.</p>
                   <p>In all of the above cases and purposes, we may analyze, profile and segment all collected data.</p>
                   <p>2.3 Legal Basis for Processing</p>
                   <p>If you are a resident of the European Union, we rely on a number of legal grounds to process information about you. For example, we will process information about you where we have your consent, where we have a legitimate interest to do so, where the processing is necessary for the performance of a contract with you, and where we have a legal obligation to process your information. For example, we rely on our legitimate interests to serve targeted marketing and on contractual necessity to process information when you create an account. To learn more about the legal grounds on which we rely to process your information for any particular purpose, please contact us via the options in Contact & Complaints below.</p>
                   <p>3. How We Share Your Personal Information</p>
                   <p>3.1 Share. We value the protection of your personal information, which is an important basis and part of the products and services we provide to you. We will only collect and use your personal information for the purposes and within the scope of this Policy or as required by law and regulations and will keep it strictly confidential. In general, we will not share your personal information with any company, organization or individual, except in the following cases:</p>
                   <ul>
                      <li><p>(a) with your prior consent;</p></li>
                      <li><p>(b) where the sharing of your personal information is required by applicable law, regulation, legal process, governmental compulsion or judicial decision;</p></li>
                      <li><p>(c) to the extent required or permitted by law, it is necessary to provide your personal information to third parties in order to protect us, its users or the public from harm to their interests, property or safety;</p></li>
                      <li><p>(d) your personal information may be shared among our affiliated companies. We will only share personal information that is necessary and such sharing is subject to the stated purposes of this Policy. If an affiliate wishes to change the purpose of processing personal information, it will again seek your authorized consent;</p></li>
                      <li><p>(e) in order to provide you with improved, quality products and services, some of our Services will be provided by authorized partners. We may share some of your personal information with our partners in order to provide better customer service and user experience. We will only share your personal information for purposes that are legal, legitimate, necessary, specific, and explicit, and only as much personal information as is necessary to provide the services. We also require our partners to handle your personal information in accordance with our instructions, this Policy, and any other relevant confidentiality and security measures. Our partners are not authorized to use the personal information shared for any other purpose. If you refuse to allow our partners to collect the personal information necessary to provide the service, you may not be able to use that third party service on our platform.</p></li>
                   </ul>
                   <p>The collection and processing of information by our partners is subject to their own privacy policy or related statements, which do not apply to this Policy. In order to maximize the security of your information, we recommend that you review the privacy policy of any third-party service before using it. To protect your legitimate rights and interests, if you find that the relevant third-party services have risks, we recommend that you immediately terminate the relevant operations and contact us in a timely manner.</p>
                   <p>We currently have access to the following third-party partner permissions:https://www.Colocess.com/3rdsdk?locale=en-US</p>
                   <p>3.2 Transfer. We will not transfer your personal information to any company, organization or individual, except for the following:</p>
                   <ul>
                      <li><p>a) Transferring with obtaining explicit consent: after obtaining your explicit consent, we will transfer your personal information to other parties;</p></li>
                      <li><p>b) Provision in accordance with applicable laws and regulations, requirements of legal proceedings, and mandatory administrative or judicial requirements as may be necessary;</p></li>
                      <li><p>c) In the event of a merger, acquisition, transfer of assets, bankruptcy and liquidation or similar transaction involving the transfer of personal information, we will require the new company or organization holding your personal information to continue to be bound by this Policy before we require the company or organization to seek your authorized consent again.</p></li>
                   </ul>
                   <p>3.3 Disclosure. We will only disclose your personal information publicly when:</p>
                   <ul>
                       <li><p>a) After obtaining your express consent;</p></li>
                       <li><p>b) Disclosure based on law: We may disclose your personal information publicly if compelled to do so by law, legal process, litigation or governmental authority.</p></li>
                   </ul>
                   <p>3.4 Without Your Prior Consent. In accordance with relevant laws and regulations and national standards, we may share, transfer, and publicly disclose personal information without obtaining your prior authorized consent in the following cases:</p>
                   <ul>
                       <li><p>a) In connection with the performance of our obligations under laws and regulations;</p></li>
                       <li><p>b) Directly related to national security and national defense security;</p></li>
                       <li><p>c) Directly related to public safety, public health, and significant public interests;</p></li>
                       <li><p>d) Directly related to crime investigation, prosecution, trial and execution of judgments, etc.;</p></li>
                       <li><p>e) For the purpose of safeguarding the life, property and other significant legitimate rights and interests of the subject of personal information or other individuals but where it is difficult to obtain his or her consent;</p></li>
                       <li><p>f) Personal information disclosed to the public by the subject of the personal information himself/herself;</p></li>
                       <li><p>g) Personal information collected from information that is legally and publicly disclosed, such as legal news reports, government information disclosure and other channels.</p></li>
                   </ul>
                   <p>In accordance with the law, sharing, transferring or publicly disclosing personal information that has been de-identified to the extent that the data recipient is unable to recover and re-identify the information subject shall not be deemed as sharing, transferring or publicly disclosing personal information, and such data shall be stored and processed without notice to or consent from you.</p>
                   <p>4. Data Retention</p>
                   <p>4.1 We will retain your personal information as described below (except if otherwise required to be retained by applicable law).</p>
                   <div class="table" style="width: 1300px; margin: 30px auto;">
                             <table align="center" style="width: 100%; border: 1px solid black; border-collapse: collapse; font-size:14px; padding:20px;">
                                 <tbody style="height: 50px; ">
                                    <th style="border: 1px solid black; width: 45%;">Personal Information</th>
                                    <th style="border: 1px solid black; width: 55%;">Retention Policy</th>
                                 </tbody>
                                 <tr>
                                   <th style="border: 1px solid black; width: 45%; padding:20px;">Nickname</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game (i.e., until account deletion in accordance with your request) and then deleted within 30 days.</th>
                                 </tr>
                                 <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Social Connect Information: WeChat</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game (i.e., until account deletion in accordance with your request or you revoke access to your social media account) and then deleted within 30 days.</th>
                                 </tr>
                                 <tr>
                                 <th style="border: 1px solid black; width: 45%;padding:20px;">Social Connect Information:Facebook</th>
                                 <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game until the account is deleted at your request, after which it will be deleted within 15days. If you do not request account deletion, this data will be deleted within 120 days after the Game is shut down or such data is no longer used by us.</th>
                                 </tr>
                                 <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Game Play Statistics (Level, Score/s)</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 30 days. Where you do not request for account deletion, such data will be retained for 1 year after the Game is shut down, before it is deleted.</th>
                                 </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Chat Communications (Audio message)</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for 30 days on our servers and deleted automatically upon expiry of such period.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Chat Communications (Text)</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 7 days. Where you do not request for account deletion, such data will be retained for 1 year after the Game is shut down, before it is deleted.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">OpenID</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 15 days. Where you do not request for account deletion, such data will be retained for 1 year after the Game is shut down, before it is deleted.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">IP Address</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 15 days. Where you do not request for account deletion, such data will be retained for 1 year after the Game is shut down, before it is deleted.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Device ID, Device Information</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 30 days.Where you do not request for account deletion, such data will be retained for 1 year after the Game is shut down, before it is deleted.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Advertising ID</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 15 days. Where you do not request for account deletion, such data will be retained for 1 year after the Game is shut down, before it is deleted.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Transaction Records</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game (i.e., until account deletion in accordance with your request) and then for the duration of any retention periods for which such data must be retained in order to comply with our legal obligations.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Customer Support Ticket ID & User Communications with Support</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Support data is deleted within 30 days of a lawful request from you for the erasure of such data (see the Erasure section of this privacy policy).</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Security-Related Information</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for the lifetime of your use of the Game, until account deletion in accordance with your request, upon which it will be deleted within 30 days.</th>
                                </tr>
                                <tr>
                                   <th style="border: 1px solid black; width: 45%;padding:20px;">Survey Information and content of survey responses</th>
                                   <th style="border: 1px solid black; width: 55%;padding:20px;">Stored for: (a) if you provide your email as part of the survey, up to 6 months (in order for us to follow up with you) and then the data is anonymized; or (b) if you do not provide your email as part of the survey, up to 1 month and then the data is anonymized.</th>
                                </tr>
                             </table>
                    </div>
                   <p>The data deletion time listed in the above is only based on the information collected by the client of our Game itself, and if we obtain information from other partners (such as App Store, Google Play, Facebook, etc.), the corresponding time may be changed, and the specific time shall be subject to the disclosed partner's privacy policy.</p>
                   <p>4.2 Personal Information is kept until the end of its life cycle (as set out in the retention policy above). When destroying personal information, measures will be taken to make the personal information irrecoverable or irreproducible, and electronic files which contain personal information will be deleted permanently using a technical method which makes the files irreproducible.</p>
                   <p>4.3 In the event that the processing and retention period have terminated, but personal information is required to be retained continuously for other reasons including for the purposes as prescribed under applicable laws, the relevant personal information will be stored and maintained separately from other types of personal information. If you require us to destroy your personal information before the end of its life cycle (as set out in the retention policy above), we will destroy your personal information in accordance with local laws.</p>
                   <h3>SECTION 2：YOUR RIGHTS</h3>
                   <p>5. This section (“Your Rights”) applies to users that are located in the European Economic Area only. For user located in other territories, please refer to the privacy clause in this policy and Colocess Terms of Service.</p>
                   <p>You have certain rights in relation to the personal information we hold about you. Some of these only apply in certain circumstances (as set out in more detail below). We must respond to a request by you to exercise those rights without undue delay and at least within one month (though this may be extended by a further two months in certain circumstances). To exercise any of your rights, please email us at kf@colocess.com.</p>
                   <p>5.1 Access</p>
                   <p>You have the right to access personal information we hold about you, how we use it, and who we share it with. You can access the personal information you have made available as part of your account by logging into your Game account and by contacting kf@colocess.com. If you believe we hold any other personal information about you, please email us at kf@colocess.com.</p>
                   <p>5.2 Portability</p>
                   <p>You have the right to receive a copy of certain personal information we process about you. This comprises any personal information we process on the basis of your consent (e.g., certain survey information) or pursuant to our contract with you, as described in the section “How We Use Your Personal Information” You have the right to receive this information in a structured, commonly used and machine-readable format. You also have the right to request that we transfer that personal information to another party.</p>
                   <p>If you wish for us to transfer such personal information to a third party, please ensure you detail that party in your request. Note that we can only do so where it is technically feasible. Please note that we may not be able to provide you with personal information if providing it would interfere with another’s rights (e.g., where providing the personal information we hold about you would reveal information about another person or our trade secrets or intellectual property).</p>
                   <p>5.3 Correction</p>
                   <p>You have the right to correct any personal information held about you that is inaccurate. You can access the personal information you have made available as part of your account by logging into your Game account. If you believe we hold any other personal information about you and that information is inaccurate, please email us at kf@colocess.com.</p>
                   <p>5.4 Erasure</p>
                   <p>You can delete your account, or remove certain personal information, by logging into your Game account. If there is any other personal information you believe we process that you would like us to erase, please email us at kf@colocess.com.</p>
                   <p>You may request that we erase the personal information we hold about you in the following circumstances:</p>
                   <ul>
                       <li><p>a) you believe that it is no longer necessary for us to hold such personal information;</p></li>
                       <li><p>b) you believe the personal information we hold about you is being unlawfully processed by us.</p></li>
                   </ul>
                   <p>You can also exercise your right to restrict our processing of your personal information (as described below) whilst we consider your request.</p>
                   <p>We may need to retain personal information if there are valid grounds under data protection laws for us to do so (e.g., for the defense of legal claims or freedom of expression) but we will let you know if that is the case. Where you have requested that we erase personal information that has been made available publicly on the Game Services and there are grounds for erasure, we will use reasonable steps to try to tell others that are displaying the personal information or providing links to the personal information to erase it too.</p>
                   <p>6. Restriction of Processing to Storage Only</p>
                   <p>You have a right to require us to stop processing the personal information we hold about you other than for storage purposes in certain circumstances. Please note, however, that if we stop processing the personal information, we may use it again if there are valid grounds under data protection laws for us to do so (e.g., for the defense of legal claims or for another’s protection). As above, where we agree to stop processing the personal information, we will try to tell any third party to whom we have disclosed the relevant personal information so that they can stop processing it too.</p>
                   <p>You may request we stop processing and just store the personal information we hold about you were:</p>
                   <ul>
                      <li><p>a) you believe the personal information is not accurate for the period it takes for us to verify whether it is accurate</p></li>
                      <li><p>b) you wish to erase the personal information as the processing we are doing is unlawful, but you want us to retain the personal information but just store it instead;</p></li>
                      <li><p>c) you wish to erase the personal information as it is no longer necessary for our purposes, but you require it to be stored for the establishment, exercise or defense of legal claims.</p></li>
                   </ul>
                   <p>7. Objection</p>
                   <p>You have the right to object to our processing of personal information about you. We will consider your request in other circumstances as detailed below by emailing us at kf@colocess.com.</p>
                   <p>8. Announcements</p>
                   <p>We may from time to time send you announcements when we consider it necessary to do so (such as when we temporarily suspend Game access for maintenance, or security, privacy or administrative-related communications). You may not opt-out of these service-related announcements, which are not promotional in nature.</p>
                   <h3>SECTION 3：MISCELLANEOUS</h3>
                   <p>9. Information security</p>
                   <p>We have implemented administrative, physical and technical security measures that are designed to protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration and destruction. You should understand though that, despite our efforts, no security can be guaranteed as impenetrable.</p>
                   <p>10. Links to Sites and Service Operated by Others</p>
                   <p>Colocess Service may contain links to other sites, applications, and services. Because Colocess does not operate those other sites, applications, and services, we cannot take responsibility for the privacy practices of the entities that operate them. We recommend that you consistently check privacy policies to understand an operator’s practices. As a reminder, this policy describes Colocess’s practices.</p>
                   <p>11. Changes</p>
                   <p>Colocess may update this privacy policy from time to time. If we make material changes in the way we collect, use, retain or share your personal information, we will notify you by sending you an email at the last email address that you provided us, or by posting notice of the changes on the services covered by this privacy policy. By continuing to access or use our Services after we have posted a modification to this policy or have provided you with notice of a modification, you are indicating that you agree to be bound by the modified terms. If the modified terms are not acceptable to you, your only recourse is to cease using our Services.</p>
                   <p>12. International Transfer</p>
                   <p>We may transfer information that we collect about you to affiliated entities, or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. Please note that these countries and jurisdictions may not have the same data protection laws as your own jurisdiction, and we take steps to ensure adequate safeguards are in place to enable transfer of information and the use and disclosure of information about you, including personal information, as described in this Policy.</p>
                   <p>13. Contact & Complaints</p>
                   <p>Questions, comments and requests regarding this policy are welcomed. Please contact us via email at kf@colocess.com.</p>
                   <p>In the event that you wish to make a complaint about how we process your personal information, please contact us in the first instance at kf@colocess.com and we will endeavor to deal with your request as soon as possible. This is without prejudice to your right to launch a claim with the data protection authority in the country in which you live or work where you think we have infringed data protection laws.</p>
                   <p>14. Jurisdiction</p>
                   <p>This policy and any action related thereto will be governed by the laws of People’s Republic of China without regard to its conflict of law’s provisions. Any dispute arising from or in connection with this Policy shall be submitted to Shanghai International Economic and Trade Arbitration Commission/Shanghai International Arbitration Center ("SHIAC") for arbitration, which shall be conducted in accordance with SHIAC's arbitration rules in effect at the time of applying for arbitration. The seat of arbitration shall be colocess, China. The language to be used in the arbitral proceedings shall be Chinese. The arbitral award is final and binding upon both parties.</p>
              `
         },
         // 联系我们
         contactUs: {
            title: "contact us",
            content: "If you have any questions, opinions or suggestions regarding this game.",
            contentOne: "Please contact us:"
         }
      }
   }

   const topUp = {
      bundle: 'Select Bundle',
      instructions: 'Top Up Details',
      products: 'No products',
      BuyNum: 'Purchase Quantity',
      Coupon: 'Coupon',
      PaymentMethod: 'Payment Method',
      logAccount: 'Log into your game account',
      logIn: 'Log in',
      PurchaseDetails: 'Purchase Details',
      Quantity: 'Quantity',
      CharacterDetails: 'Character Details',
      Price: 'Price',
      Discount: 'Discount',
      noDiscount:'No discount',
      Coupon: 'Coupon',
      cooperation: "Official Cooperation",
      Contents: 'Contents',
      SelectB: 'Select a bundle.',
      SelectP:'Please select payment method',
      termofserviceTitle1: 'TERMS OF USE',
      termofserviceTitle2: 'PRIVACY POLICY',
      total: 'Total',
      payText: 'Pay',
      Tips: 'Coming Soon',
      content:'content',
      payType:['During the payment','Payment completion','During the payment'],
      statuDON:'You have successfully topped up',
      statuDTO:'consumption',
      statuDTH:'Top up game',
      statuDFO:'Top up account',
      statuDFI:'Order ID',
      statuDSI:'Recharge time',
      orderList:'Order list',
      location:"Location: My order",
      search:"search"
    }
    const l = {
      one:'Verification code login',
      tow:'Password Login',
      therr:'Enter your email address',
      four:'Enter your email address',
      five:'Enter your verification code',
      six:'Enter your verification code',
      seven:'I have read and agree to the',
      Eight:'Get code',
      Nine:'',
    
    }
    
    const login = {
      "One": "Account login",
      "Tow": "Please enter your account number",
      "Therr": "Please enter password",
      "Four": "Please enter the verification code",
      "Five": "Log in",
      "Six": "Third-party login mode",
      "Seven": "Email login",
      "Eight": "Please enter email",
      "Nine": "Get code",
      "Ten": "Sign in",
      "Eleven": "Register an account",
      "Twelve": "The account or password is incorrect",
      "thirteen": "Verification code error",
      "mistake":"mistake",
      "registerSus":"Registered successfully",
      "loginSus":'Login successful',
      "sentSus":'Sent successfully',
      "phoneLogin":"Mobile number login",
      "inPhone":"Please enter your phone number",
      "otherLogin":"Other login methods",
      "pleaseCheck":'Please read and agree to the Terms of Service and Privacy Agreement',
      "loginOut":'Log out',
      "TermsService": "Terms of Service",
      "PrivacyAgreement": "Privacy agreement",
    }

    export {
      messages,
      topUp,
      l,
      login
    }