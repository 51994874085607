
export default {
	IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },
	textRepl(str){
		let reple = process.env.VUE_APP_IMAGEURLRPLE 
		let url = process.env.VUE_APP_IMAGEURL 
		let newstr = str.replaceAll(reple, url + reple)
		return newstr
	},
	imgTrow(str,ISSDK = false){
		let url = !ISSDK ? process.env.VUE_APP_IMAGEURL  : process.env.VUE_APP_SDKIMAGEURL
		return  url + str
	}
}
