import { mapState } from "vuex";
export default {
  data() {
    return {
      currencyIcon: [
        {
          url: require("../../assets/imgs/CNY.png"),
          label: "CNY",
        },
        {
          url: require("../../assets/imgs/USD.png"),
          label: "USD",
        },
        {
          url: require("../../assets/imgs/VND.png"),
          label: "VND",
        },
      ],
    };
  },
  computed: {},
  created() {},
  watch: {},
  mounted() {},
  methods: {},
};
