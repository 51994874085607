import { assign, map } from 'lodash'
import { service, request, serviceForMock, requestForMock } from './service'
import * as tools from './tools'

const files = require.context('./modules', true, /\.js$/)
const generators = files.keys().map(key => files(key).default)

export default assign({}, ...map(generators, generator => generator({
  service,
  request,
  serviceForMock,
  requestForMock,
  tools
})))