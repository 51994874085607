import router from '@/router'
import utils from '@/utils/utils.js'

const user = {
  state: {
    token:'',
    userInfo:{},
    loginModel:false,
  },

  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    loginOut(state,data){
      state.userInfo = {}
      // 返回商品页面
      if(utils.IsPC()){
        router.replace({ path: '/PaymentDetalis', })
      }else{
        router.replace({ path: '/PaymentDetalisM', })
      }
    }
  },
  actions: {
  }
}
export default user
