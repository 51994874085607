<template>
  <div class="loadingMark" v-if="isLoading">
    <div class="loading"></div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      isLoading: false,
    }
  },
  created() {

  },
  methods: {
    open() {
      this.isLoading = true
    },
    hide() {
      this.isLoading = false

    }
  },
}
</script>
<style lang="scss" scoped>
.loading {
  width: 30px;
  height: 30px;
  border: 2px solid #409EFF;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
}

// 转转转动画
@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingMark {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.1);

}
</style>
