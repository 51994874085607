<template>
  <!-- 下面的商标 -->
  <div class="ql-container bottom-footer">
    <div class="ql-editor btm-ctn" v-html="$utils.textRepl(content)"></div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mapState } from "vuex";

export default {
  data() {
    return {
      content: '',
    }
  },
  created() {
    this.getAg()
  },
  computed: {
    ...mapState({
      lang: (state) => state.init.lang,
    }),
  },
  watch: {
    lang(nval) {
      this.getAg()
    }
  },
  methods: {
    getAg() {
      this.$api.copyrightAll().then(res => {
        if (res.code == 0) {
          this.content = res.data[0] ? res.data[0].copyright : ''
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bottom-footer {
  position: relative;
  background-color: #363636;
  min-height: 150px;
  padding: 40px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 999;
}
</style>