import { mapState } from "vuex";
import sdk from "@/mixins/module/sdk";
import publicjs from "@/mixins/module/publicjs";

export default {
  mixins: [sdk,publicjs],
  data() {
    return {
      bundleDelShow: false,
      bundleDelShowAn: false,
      productModelData: {},
      active: null,
      num: 1,
      payIndex: null,
      isPayreq: true,
      dateText: "",
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.init.lang,
      gameData: (state) => state.payment.gameData,
      serverData: (state) => state.payment.serverData,
      productPrimitiveList: (state) => state.payment.productPrimitiveList,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created() {},
  watch:{
    lang(nval){
      this.closeProduct(this.productPrimitiveList[this.active],this.active)
    },
    userInfo(nval) {
      this.dateCountdownTime();
    },
  },
  mounted() {},
  activated() {
    this.dateCountdownTime();
    
  },
  methods: {
    openPop(item) {
      this.closeProduct(item);
      this.bundleDelShow = true;
      setTimeout(() => {
        this.bundleDelShowAn = true;
      }, 100);
    },
    // 选择礼包操作
    closeProduct(item, index) {
      if(!item){
        return
      }
      if (index !== undefined) {
        this.active = index;
      }
      
      this.productModelData = { ...item };
      let showDiscount = parseInt(item.discount) == 1 ? this.$t('topUp.noDiscount') : item.discount  // 展示折扣
      let showPrice = '' // 展示金额
      let price = item.price * this.num * Number(item.discount || 1)
      if(price % 1 == 0){
        showPrice = price / 100
      }
      if(!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(showPrice)){
        showPrice = showPrice.toFixed(2)
      }
      this.productModelData.showDiscount =  showDiscount   //展示折扣
      this.productModelData.showPrice =  showPrice  //计算价格
      this.productModelData.originalPrice =  item.price / 100   //原价
      
    },
    dateCountdownTime() {
      //获取当前时间
      let date = new Date();
      let now = date.getTime();
      //设置截止时间
      let str = "2018/3/17 00:00:00";
      let endDate = new Date(str);
      let end = this.userInfo.delete_time * 1000 ;
      console.log(end)
      if (!end && this.userInfo.user_id) {
        return;
      }
      //时间差
      let leftTime = end - now;
      //定义变量 d,h,m,s保存倒计时的时间
      let d, h, m, s;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
        //将0-9的数字前面加上0，例1变为01
        d = checkTime(d);
        h = checkTime(h);
        m = checkTime(m);
        s = checkTime(s);
        function checkTime(i) {
          if (i < 10) {
            i = "0" + i;
          }
          return i;
        }
        if (d == "00" && h == "00" && m == "00" && s == "00") {
          this.$store.commit("loginOut");
          return;
        }
        this.dateText = `账号删除中 ${d !== "00" ? d + "天" + (d !== "00" ? "-" : "") : ""}${h !== "00" ? h + "时" + (h !== "00" ? "-" : "") : ""}${m !== "00" ? m + "分" + (s !== "00" ? "-" : "") : ""}${s !== "00" ? s + "秒" : ""}`;
        //递归每秒调用countTime方法，显示动态时间效果
        setTimeout(this.dateCountdownTime, 1000);
      }else{
        this.dateText =  '账号已删除'
      }
    },
    createOrder() {
      if(this.userInfo.loginToken == undefined){
        // this.$refs.optionPopupText.open(this.$t('topUp.logAccount'))
        this.loginChange()
        return
      }
      if(this.active == null){
        this.$refs.optionPopupText.open(this.$t('topUp.SelectB'))
        return
      }
      if (this.payIndex == null ) {
        this.$refs.optionPopupText.open(this.$t('topUp.SelectP'))
        return;
      }
      this.isLoading = true;
      let sdkData = {
        appid: this.gameData.appid.toString(),
        developer_order_id: "",
        gate_user_id:this.userInfo.gate_user_id.toString(),
        login_user_id:this.userInfo.sdk_user_id.toString(),
        developer_user_id:this.userInfo.developer_user_id.toString(),
        device_id: this.guid(),
        server_id: this.serverData.id.toString(),
        product: this.productModelData.cp_product,
        product_name: this.productModelData.params.product_name,
        product_desc: this.productModelData.params.content,
        price: this.productModelData.price * this.num * Number(this.productModelData.discount),
        currency: this.productModelData.currency,
        product_id:this.productModelData.id
      };  
      let data = {
        device_id: sdkData.device_id,
        product_id: sdkData.product_id,
        product_name: sdkData.product_name,
        product_desc: sdkData.product_desc,
      }
      this.$api.createdOrder(data).then(res=>{
        if(res.code == 0){
          this.sdkcreateOrder({...sdkData,developer_order_id:res.data.developer_order_id}).then(resd=>{
            if(resd.code == 200){
              this.$router.push({ path: "/PaymentCallback", query: { orderID: res.data.order_id,product_name:sdkData.product_name,price:sdkData.price,currency:sdkData.currency, index: this.active } });
            }
          })
        }
      })
    },
    //
    hiad() {
      this.bundleDelShowAn = false;
      setTimeout(() => {
        this.bundleDelShow = false;
      }, 300);
    },
    numOp(type) {
      return
      if (type == 1) {
        this.num = this.num <= 1 ? 1 : (this.num -= 1);
      }
      if (type == 2) {
        this.num = this.num >= 99 ? 99 : (this.num += 1);
      }
    },
    loginChange() {
      if (this.userInfo.loginToken) {
        this.$store.commit("loginOut");
      } else {
        this.$bus.$emit("logMd");
      }
    },
  },
};
