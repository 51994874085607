import Vue from 'vue'
import bus from "@/utils/bus"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import '@/common/style.css' // global css
import utils from '@/utils/utils';
import '@/assets/styles/font.css' 

Vue.prototype.$bus = bus
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
Vue.prototype.$utils = utils

import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import "./common/Editor.css"

Vue.prototype.$imgUrl = process.env.VUE_APP_API
import loginModel from "@/components/loginModel"
import optionPopupText from "@/components/optionPopupText"
import loadingMark from "@/components/loadingMark"
Vue.component('loginModel', loginModel)
Vue.component('optionPopupText', optionPopupText)
Vue.component('loadingMark', loadingMark)
import api from '@/api'
Vue.prototype.$api = api
const i18n = new VueI18n({ // 双语
  locale: store.state.init.lang,
  messages: {
    'cn': require('./assets/lg/cn'),
    'en': require('./assets/lg/en')
  }
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
