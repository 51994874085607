export default ({ service, request, serviceForMock, requestForMock, mock, tools }) => ({
  // 生成订单
  createdOrder(data = {}) {
    return request({
      url: "/api/createdOrder",
      method: "post",
      params: data,
    });
  },
  // 查询订单状态
  sdkGetOrderStatus(data = {}) {
    return request({
      url: "/api/sdkGetOrderStatus",
      method: "get",
      params: data,
    });
  },
  // openGame查询订单
  openGameOrderStatus(data = {}) {
    return request({
      url: "/api/openGameOrderStatus",
      method: "post",
      params: data,
    });
  },
});
