export default ({
  service,
  request,
  serviceForMock,
  requestForMock,
  tools
}) => ({
  //图片上传 
  upload(data = {}) {
    return request({
      url: '/api/admin/get-oss-upload-params',
      method: 'get',
      params: data
    })
  },
  //上传配置 
  uploadParams(data = {}) {
    return request({
      url: '/api/admin/get-oss-upload-params',
      method: 'get',
      params: data
    })
  },
  // 获取配置信息
  payConfigurationLogToList(data = {}) {
    return request({
      url: '/api/payConfiguration/payConfigurationLogToList',
      method: 'get',
      params: data
    })
  },
})
